import { Observable, filter, map } from 'rxjs';
import { WorkerSynchronizerContract } from './worker-synchronizer-contract';

type WorkerSynchronizerMessage = {
  payload?: Record<string, any>;
  channelName?: string;
};

const isBroadcastMessage =
  (channelName: string) =>
  (message: Record<string, any>): message is WorkerSynchronizerMessage =>
    message?.channelName === channelName;

export class WorkerSynchronizerSimple {
  constructor(
    private _channelName: string,
    private _messenger: WorkerSynchronizerContract
  ) {}

  public next<T>(message: T): void {
    const outcomeMessage: WorkerSynchronizerMessage = {
      payload: message as Record<string, any>,
      channelName: this._channelName
    };
    this._messenger.postMessage(outcomeMessage);
  }

  public get message$(): Observable<Record<string, any>> {
    return this._messenger.getMessage<WorkerSynchronizerMessage>().pipe(
      filter(isBroadcastMessage(this._channelName)),
      map((message: WorkerSynchronizerMessage) => message.payload!)
    );
  }
}
