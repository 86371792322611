import { Injectable } from '@angular/core';
import { PlaceBetApiError } from '@bcf-logic-api-access/errors/place-bet-api-error';

export interface PlaceBetGuardContract {
  canPlaceBet(): Promise<boolean>;
  getPlaceBetError(): Promise<PlaceBetApiError | undefined>;
}

@Injectable({ providedIn: 'root' })
export class PlaceBetGuard implements PlaceBetGuardContract {
  public async canPlaceBet(): Promise<boolean> {
    return true;
  }

  public async getPlaceBetError(): Promise<PlaceBetApiError | undefined> {
    return undefined;
  }
}
