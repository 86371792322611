/*
  WE HAVE ID AND CODE AS PROPERTY OPTIONAL HERE
  ON LVBETCOM (BECAUSE THIS CMS IS FUCKED UP) AND USES ID IN CMS DATABASE INSTEAD OD CODE.
  TO HELP WITH THIS WE USE FUNCTIO AND PIPE "findByCodeOrId"
*/

export type CmsDepositBonusOrFreespin = {
  readonly code: string | undefined;
  readonly id: number | undefined;
  readonly title: string;
  readonly subtitle: string;
  readonly description: string;
  readonly termsContentHtml: string;
  readonly imgPath: string;
};

export function createCmsDepositBonusOrFreespinFromHttpJson(json: Record<string, any>): CmsDepositBonusOrFreespin {
  return {
    code: json['code'] ?? undefined,
    id: json['id'] ?? undefined,
    title: json['title'],
    subtitle: json['subtitle'],
    description: json['description'],
    termsContentHtml: json['termsContentHtml'],
    imgPath: json['imgPath']
  };
}

export function createCmsDepositBonusOrFreespinFromHttpHeadlessJson(
  json: Record<string, any>
): CmsDepositBonusOrFreespin {
  return {
    code: undefined,
    id: json['id'] ?? undefined,
    title: json['title'],
    subtitle: json['subtitle'],
    description: json['description'],
    termsContentHtml: json['longTerms'],
    imgPath: json['image']
  };
}

export function createCmsDepositBonusOrFreespinFromHttpHeadlessJsonArray(
  jsons: Record<string, any>[]
): CmsDepositBonusOrFreespin[] {
  return jsons.map(createCmsDepositBonusOrFreespinFromHttpHeadlessJson);
}

export function createCmsDepositBonusOrFreespinFromHttpJsonArray(
  jsons: Record<string, any>[]
): CmsDepositBonusOrFreespin[] {
  return jsons.map(createCmsDepositBonusOrFreespinFromHttpJson);
}
