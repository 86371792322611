import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const LOCALSTORAGE: InjectionToken<Storage> = new InjectionToken('LocalStorageToken');

class VirtualLocalStorage implements Storage {
  private _storage: Record<string, any> = {};

  public clear(): void {
    this._storage = {};
  }
  public getItem(key: string): string | null {
    return this._storage[key] ?? null;
  }
  public key(index: number): string | null {
    const keys: string[] = Object.keys(this._storage);
    return keys[index] ?? null;
  }
  public removeItem(key: string): void {
    this._storage[key] = undefined;
  }
  public setItem(key: string, value: string): void {
    this._storage[key] = value;
  }

  public get length(): number {
    return Object.keys(this._storage).length;
  }
}

export function provideBrowserLocalStorage(): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: LOCALSTORAGE, useFactory: () => localStorage ?? new VirtualLocalStorage() }
  ]);
}

export function provideServerLocalStorage(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: LOCALSTORAGE, useFactory: () => new VirtualLocalStorage() }]);
}
