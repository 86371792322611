import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export abstract class WebAuthApi {
  public isUserVerifyingPlatformAuthenticatorAvailable(): Promise<boolean> {
    throw new Error('Not implemented.');
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WEB_AUTH_API: InjectionToken<WebAuthApi> = new InjectionToken('WebAuthApi');

export class BrowserWebAuthApi implements WebAuthApi {
  public isUserVerifyingPlatformAuthenticatorAvailable(): Promise<boolean> {
    if (window.PublicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable) {
      return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    }
    return Promise.resolve(false);
  }
}

export function provideBrowserWebAuthApi(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: WEB_AUTH_API, useFactory: () => new BrowserWebAuthApi() }]);
}

export function provideServerWebAuthApi(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: WEB_AUTH_API, useFactory: () => new Object() }]);
}
