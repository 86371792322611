import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/sportsbook/letsbetmd/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.letsbetmd.com/',
  walletApiUrl: 'https://wallet.letsbetmd.com/',
  paymentApiUrl: 'https://payment.letsbetmd.com/',
  cmsApiUrl: {
    default: 'https://letsbetmd.com/'
  },
  cmsHeadlessApiUrl: 'https://cms.letsbetmd.com/',
  shadowStylesUrl: {
    default: 'https://lvbet-cms3.testowaplatforma123.net/shadow-css/'
  },
  geocomplyWebServiceUrl: 'https://us6-oobee-v2.geocomply.com/',
  feEnv: {
    baseUrl: 'https://letsbetmd.com',
    savePinLoginEndpoint: '/save-pin-login/',
    pinLoginEndpoint: '/pin-login/'
  }
};
