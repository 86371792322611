import { InjectionToken } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MATCH_OFFER_DATE_TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>(
  'matchOfferDateTimeFormat'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BETSLIP_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('betslipDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MY_BETS_MATCH_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('myBetsMatchDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MY_BETS_EXTRACTED_BET_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>(
  'myBetsExtractedBetDateFormat'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MY_BETS_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('myBetsDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MY_BETS_LIST_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('myBetsListDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USER_PANEL_DATE_TIME_FORMAT: InjectionToken<string> = new InjectionToken<string>('userPanelDateFormat');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESULT_CENTER_DATE_FORMAT: InjectionToken<string> = new InjectionToken<string>('resultCenterDateFormat');
