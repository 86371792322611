import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { registerCpProductType, registerEnvCp } from '@bcf-vanilla-ts-v1-api-access/config-tokens/env-cp';

export type EnvCp = {
  userApiUrl: string;
  walletApiUrl: string;
  paymentApiUrl: string;
  cpSocketUrl1?: string;
};

export type CpProductType = 'casino' | 'sportsbook';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_CP: InjectionToken<EnvCp> = new InjectionToken<EnvCp>('EnvCp');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CP_PRODUCT_TYPE: InjectionToken<CpProductType> = new InjectionToken<CpProductType>('CpProductType');

export function provideEnvCp(envCp: EnvCp): EnvironmentProviders {
  registerEnvCp(envCp);
  return makeEnvironmentProviders([{ provide: ENV_CP, useValue: envCp }]);
}

export function provideCpProductType(productType: CpProductType): EnvironmentProviders {
  registerCpProductType(productType);
  return makeEnvironmentProviders([{ provide: CP_PRODUCT_TYPE, useValue: productType }]);
}
