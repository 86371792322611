import { DeviceInfoPayload } from '@bcf-vanilla-ts-v1-utilities/device-info/types';
import { WorkerBlocId } from '../worker-blocs.type';

export type WorkerSideMessage =
  | CreateInstanceMessage
  | DestroyInstanceMessage
  | SubscribeToChannelMessage<any>
  | UnsubscribeFromChannelMessage<any>
  | SubjectNextMessage
  | WorkerReadyMessageAck;

export type BrowserSideMessage =
  | InstanceCreatedMessage
  | SubscriptionPayloadMessage
  | SubscriptionErrorMessage
  | SubscriptionCompleteMessage
  | InitWorkerMessage
  | WorkerReadyMessage
  | WorkerCookiesMessage
  | WorkerDeviceInfoMessage
  | WorkerIsUserActiveInAppMessage
  | WorkerUrlMessage
  | WorkerLangMessage;

export type CreateInstanceMessage = {
  action: 'createInstance';
  workerBlocId: WorkerBlocId;
  uniqueId: string;
};
export const createInstanceMessage = (workerBlocId: WorkerBlocId, uniqueId: string): CreateInstanceMessage => ({
  action: 'createInstance',
  workerBlocId: workerBlocId,
  uniqueId: uniqueId
});

export type DestroyInstanceMessage = {
  action: 'destroyInstance';
  workerBlocId: WorkerBlocId;
  uniqueId: string;
};
export const destroyInstanceMessage = (workerBlocId: WorkerBlocId, uniqueId: string): DestroyInstanceMessage => ({
  action: 'destroyInstance',
  workerBlocId: workerBlocId,
  uniqueId: uniqueId
});

export type SubscribeToChannelMessage<T> = {
  action: 'subscribeToChannel';
  workerBlocId: WorkerBlocId;
  subId: string;
  uniqueId: string;
  channelName: T;
};
export const subscribeToChannelMessage = <T>(
  workerBlocId: WorkerBlocId,
  uniqueId: string,
  subId: string,
  channelName: T
): SubscribeToChannelMessage<T> => ({
  action: 'subscribeToChannel',
  workerBlocId: workerBlocId,
  subId: subId,
  uniqueId: uniqueId,
  channelName: channelName
});

export type UnsubscribeFromChannelMessage<T> = {
  action: 'unsubscribeFromChannel';
  workerBlocId: WorkerBlocId;
  subId: string;
  uniqueId: string;
  channelName: T;
};
export const unsubscribeFromChannelMessage = <T>(
  workerBlocId: WorkerBlocId,
  uniqueId: string,
  subId: string,
  channelName: T
): UnsubscribeFromChannelMessage<T> => ({
  action: 'unsubscribeFromChannel',
  workerBlocId: workerBlocId,
  subId: subId,
  uniqueId: uniqueId,
  channelName: channelName
});

export type SubjectNextMessage = {
  action: 'subjectNext';
  uniqueId: string;
  subjectKey: any;
  payload: any;
};

export const subjectNextMessage = (uniqueId: string, subjectKey: any, payload: any): SubjectNextMessage => ({
  action: 'subjectNext',
  uniqueId: uniqueId,
  subjectKey: subjectKey,
  payload: payload
});

export type InstanceCreatedMessage = {
  action: 'instanceCreated';
  workerBlocId: WorkerBlocId;
  uniqueId: string;
};
export const instanceCreatedMessage = (workerBlocId: WorkerBlocId, uniqueId: string): InstanceCreatedMessage => ({
  action: 'instanceCreated',
  uniqueId: uniqueId,
  workerBlocId: workerBlocId
});

export type SubscriptionPayloadMessage = {
  action: 'subscriptionPayload';
  uniqueId: string;
  subId: string;
  channelName: any;
  payload: any;
};
export const subscriptionPayloadMessage = (
  uniqueId: string,
  subId: string,
  channelName: any,
  payload: any
): SubscriptionPayloadMessage => ({
  action: 'subscriptionPayload',
  uniqueId: uniqueId,
  subId: subId,
  channelName: channelName,
  payload: payload
});

export type SubscriptionErrorMessage = {
  action: 'subscriptionError';
  subId: string;
  uniqueId: string;
  channelName: any;
  error: Error;
};
export const subscriptionErrorMessage = (
  uniqueId: string,
  subId: string,
  channelName: any,
  error: Error
): SubscriptionErrorMessage => ({
  action: 'subscriptionError',
  uniqueId: uniqueId,
  subId: subId,
  channelName: channelName,
  error: error
});

export type SubscriptionCompleteMessage = {
  action: 'subscriptionComplete';
  subId: string;
  uniqueId: string;
  channelName: any;
};
export const subscriptionCompleteMessage = (
  uniqueId: string,
  subId: string,
  channelName: any
): SubscriptionCompleteMessage => ({
  action: 'subscriptionComplete',
  subId: subId,
  uniqueId: uniqueId,
  channelName: channelName
});

export type InitWorkerMessage = {
  action: 'initWorker';
  payload: {
    appRuntimeId: number;
    hostname: string;
  };
};
export const initWorkerMessage = (appRuntimeId: number, hostname: string): InitWorkerMessage => ({
  action: 'initWorker',
  payload: {
    appRuntimeId: appRuntimeId,
    hostname: hostname
  }
});

export type WorkerReadyMessage = {
  action: 'workerReady';
};

export const workerReady = (): WorkerReadyMessage => ({
  action: 'workerReady'
});

export type WorkerReadyMessageAck = {
  action: 'workerReadyAck';
};

export const workerReadyAck = (): WorkerReadyMessageAck => ({
  action: 'workerReadyAck'
});

export type WorkerCookiesMessage = {
  action: 'workerCookies';
  cookiesStr: string;
};
export const workerCookiesMessage = (cookiesStr: string): WorkerCookiesMessage => ({
  action: 'workerCookies',
  cookiesStr: cookiesStr
});

export type WorkerDeviceInfoMessage = {
  action: 'workerDeviceInfo';
  deviceInfo: DeviceInfoPayload;
};
export const workerDeviceInfoMessage = (deviceInfo: DeviceInfoPayload): WorkerDeviceInfoMessage => ({
  action: 'workerDeviceInfo',
  deviceInfo: deviceInfo
});

export type WorkerIsUserActiveInAppMessage = {
  action: 'workerIsUserActiveInApp';
  isUserActiveInApp: boolean;
};
export const workerIsUserActiveInAppMessage = (isUserActiveInApp: boolean): WorkerIsUserActiveInAppMessage => ({
  action: 'workerIsUserActiveInApp',
  isUserActiveInApp: isUserActiveInApp
});

export type WorkerUrlMessage = {
  action: 'workerUrl';
  currentPathname: string;
  currentFullUrl: string;
};
export const workerUrlMessage = (pathname: string, fullUrl: string): WorkerUrlMessage => ({
  action: 'workerUrl',
  currentPathname: pathname,
  currentFullUrl: fullUrl
});

export type WorkerLangMessage = {
  action: 'workerLang';
  lang: string;
};
export const workerLangMessage = (lang: string): WorkerLangMessage => ({
  action: 'workerLang',
  lang: lang
});
