export class CmsWithdrawMethod {
  constructor(
    public readonly cpBackendId: number,
    public readonly name: string,
    public readonly cssClass: string,
    public readonly additionalFormContent: string | undefined
  ) {}

  public static fromJson(json: Record<string, any>): CmsWithdrawMethod {
    return new CmsWithdrawMethod(
      json['id'],
      json['name'],
      json['cssClass'],
      json['contentFormInfo']?.length > 0 ? json['contentFormInfo'] : undefined
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): CmsWithdrawMethod[] {
    return jsons.map(CmsWithdrawMethod.fromJson);
  }
}
