import { Injectable } from '@angular/core';

export interface BcfRouterRedirector {
  redirectToUrl(_url: string): string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class BcfRouterRedirectorNoop implements BcfRouterRedirector {
  /*
  here you can catch url and make external redirect or rewrite to another url
  for example when you receive url for privacy policy you can open it in another tab or window.
  or change url for other url
  */
  public redirectToUrl(_url: string): string | undefined {
    throw new Error('redirect failed due missing implementation');
  }
}
