// eslint-disable-next-line @typescript-eslint/typedef
export const environmentCommon = {
  cmsLangPrefix: true,
  cmsDevicePrefix: true,
  cmsBrandNamePrefix: false,
  cmsSlashOnTheEnd: false,
  payAndPlayAuthorization: {
    username: 'paymentinternalapi',
    password: 'tEst,739.184;',
    // if we want generate authorization key, we can use window.btoa function ex. `basic ${window.btoa('username:password')}`
    authorizationKey: 'basic cGF5bWVudGludGVybmFsYXBpOnRFc3QsNzM5LjE4NDs='
  }
};
