import { PlatformLocation } from '@angular/common';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { BrowserMemorizedPlatformLocation } from './browser-memorized-platform-location';
import { provideBrowserCryptoSubtle } from './crypto-subtle-provider';
import { provideBrowserHistory } from './history-provider';
import { provideBrowserIntlDateTimeFormat } from './intl-date-time-format-provider';
import { provideBrowserLocalStorage } from './local-storage-provider';
import { provideBrowserNavigator } from './navigator-provider';
import { provideBrowserSessionStorage } from './session-storage-provider';
import { provideBrowserWebAuthApi } from './web-auth-api-provider';
import { provideBrowserWindow } from './window-provider';

export function providePlatformBrowserApis(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideBrowserHistory(),
    provideBrowserIntlDateTimeFormat(),
    provideBrowserWindow(),
    provideBrowserLocalStorage(),
    provideBrowserSessionStorage(),
    provideBrowserWebAuthApi(),
    provideBrowserNavigator(),
    provideBrowserCryptoSubtle(),
    { provide: PlatformLocation, useClass: BrowserMemorizedPlatformLocation }
  ]);
}
