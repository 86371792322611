import { Injectable } from '@angular/core';
import { MyBetsCount } from '@bcf-v2-api-access/bp-api/models/my-bets-count';
import { ExtraTerms } from '@bcf-v2-api-access/cp-api/models/extra-terms';
import { UserBalance } from '@bcf-v2-api-access/cp-api/models/user-balance';
import { UserInfoDetails } from '@bcf-v2-api-access/cp-api/models/user-info-details';
import { WalletInfoMisc } from '@bcf-v2-api-access/cp-api/models/wallet-info-misc';
import { UserDataInfoContract } from '@bcf-v2-logic-worker-auth/services/user-data-info';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { CpLoginInfo } from '@bcf-vanilla-ts-v1-api-access/cp-api/models/cp-login-info';
import { CpUserInfo } from '@bcf-vanilla-ts-v1-api-access/cp-api/models/cp-user-info';
import { CpUserInfoNotLoggedIn } from '@bcf-vanilla-ts-v1-api-access/cp-api/models/cp-user-info-not-logged-in';
import { transferRxNext } from '@bcf-vanilla-ts-v1-shared/misc/rx-helpers/transfer-rx-next';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataInfoSyncedLegacy implements UserDataInfoContract {
  public countryIp$: ReplaySubject<string> = new ReplaySubject<string>(1);
  public userCountry$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);

  public myBetsCount$: BehaviorSubject<MyBetsCount | undefined> = new BehaviorSubject<MyBetsCount | undefined>(
    undefined
  );

  public loginInfo$: BehaviorSubject<CpLoginInfo | undefined> = new BehaviorSubject<CpLoginInfo | undefined>(undefined);
  public userInfo$: ReplaySubject<CpUserInfo | undefined> = new ReplaySubject<CpUserInfo | undefined>(undefined);
  public userInfoNotLoggedIn$: ReplaySubject<CpUserInfoNotLoggedIn | undefined> = new ReplaySubject<
    CpUserInfoNotLoggedIn | undefined
  >(undefined);
  public userInfoDetails$: ReplaySubject<UserInfoDetails | undefined> = new ReplaySubject<UserInfoDetails | undefined>(
    1
  );
  public walletInfoMisc$: BehaviorSubject<WalletInfoMisc | undefined> = new BehaviorSubject<WalletInfoMisc | undefined>(
    undefined
  );
  public userBalance$: ReplaySubject<UserBalance | undefined> = new ReplaySubject<UserBalance | undefined>(1);
  public extraTerms$: ReplaySubject<ExtraTerms | undefined> = new ReplaySubject<ExtraTerms | undefined>(1);
  public lastLoginTime$: ReplaySubject<number | undefined> = new ReplaySubject<number | undefined>(1);

  private _loginInfoChannel: WorkerSynchronizer<CpLoginInfo | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerLoginInfoSynced');

  private _myBetsCountChannel: WorkerSynchronizer<MyBetsCount | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerMyBetsCountSynced');

  private _userInfoChannel: WorkerSynchronizer<CpUserInfo | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoSynced');

  private _userInfoNotLoggedInChannel: WorkerSynchronizer<CpUserInfoNotLoggedIn | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoNotLoggedInSynced');

  private _userUserInfoDetailsChannel: WorkerSynchronizer<UserInfoDetails | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserInfoDetailsSynced');

  private _userWalletInfoMiscChannel: WorkerSynchronizer<WalletInfoMisc | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerWalletInfoMiscSynced');

  private _userUserBalanceChannel: WorkerSynchronizer<UserBalance | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerUserBalanceSynced');

  private _userExtraTermsChannel: WorkerSynchronizer<ExtraTerms | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerExtraTermsSynced');

  private _userCountryIpChannel: WorkerSynchronizer<string> =
    this._workerSynchronizerSpawner.spawn('_workerCountryIpSynced');

  private _userCountryChannel: WorkerSynchronizer<string | undefined> =
    this._workerSynchronizerSpawner.spawn('_workerCountrySynced');

  constructor(private _workerSynchronizerSpawner: WorkerSynchronizerSpawner) {
    this._init();
  }

  private _init(): void {
    this._loginInfoChannel.message$.subscribe(transferRxNext(this.loginInfo$));
    this._myBetsCountChannel.message$.subscribe(transferRxNext(this.myBetsCount$));
    this._userInfoChannel.message$.subscribe(transferRxNext(this.userInfo$));
    this._userInfoNotLoggedInChannel.message$.subscribe(transferRxNext(this.userInfoNotLoggedIn$));
    this._userUserInfoDetailsChannel.message$.subscribe(transferRxNext(this.userInfoDetails$));
    this._userWalletInfoMiscChannel.message$.subscribe(transferRxNext(this.walletInfoMisc$));
    this._userUserBalanceChannel.message$.subscribe(transferRxNext(this.userBalance$));
    this._userExtraTermsChannel.message$.subscribe(transferRxNext(this.extraTerms$));
    this._userCountryIpChannel.message$.subscribe(transferRxNext(this.countryIp$));
    this._userCountryChannel.message$.subscribe(transferRxNext(this.userCountry$));
  }
}
