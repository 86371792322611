import { InjectionToken } from '@angular/core';
import { BcfAppId } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BCF_APP_ID: InjectionToken<BcfAppId> = new InjectionToken<BcfAppId>('bcfAppId');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BCF_RELEASE_VERSION: InjectionToken<string> = new InjectionToken<string>('releaseVersion');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BCF_PRODUCTION_MODE: InjectionToken<boolean> = new InjectionToken<boolean>('bcfProductionMode');
