export function extractBaseLang(availableLangs: string[], document: Document): string {
  return availableLangs.find((lang: string) => document.location.pathname.split('/').includes(lang)) ?? 'en';
}

export function extractBaseLangFromUrl(availableLangs: string[], document: Document): string {
  const baseLangFromSegment: string | undefined = availableLangs.find((lang: string) =>
    document.location.pathname.split('/').includes(lang)
  );
  if (baseLangFromSegment) {
    return baseLangFromSegment;
  }

  // lang can be 2char length or 5 length (en-GB)
  const langFromUrl: string | undefined = document.location?.pathname?.split('/')?.[1];
  const lang2CharPass: boolean = langFromUrl.length === 2;
  const lang5CharPass: boolean = langFromUrl.includes('-') && langFromUrl.length === 5;
  if (langFromUrl && langFromUrl.length > 0 && (lang2CharPass || lang5CharPass)) {
    return langFromUrl;
  }

  return 'en';
}
