import { Inject, Injectable } from '@angular/core';
import { ENV_BASE, EnvBase } from '@bcf-v2-configs/env-base';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { ReplaySubject, lastValueFrom, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CurrentLang {
  private _baseLang$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    @Inject(ENV_BASE) private _envBase: EnvBase,
    private _workerSynchronizerSpawner: WorkerSynchronizerSpawner
  ) {
    const langBaseChannel: WorkerSynchronizer<string> = this._workerSynchronizerSpawner.spawn('langBaseChannel');
    langBaseChannel.message$.subscribe((baseLang: string) => {
      this._baseLang$.next(baseLang);
    });
  }

  public getBaseLang(): Promise<string> {
    return lastValueFrom(this._baseLang$.pipe(take(1)));
  }

  public async getApiLang(): Promise<string> {
    return lastValueFrom(this._baseLang$.pipe(take(1)));
  }

  public async getLangForTranslations(): Promise<string> {
    const baseLang: string = await lastValueFrom(this._baseLang$.pipe(take(1)));
    return this._envBase.translationsLangsMap?.[baseLang] ?? baseLang;
  }
}
