import { HttpErrorResponse } from '@angular/common/http';
import { of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class PlaceBetApiError {
  constructor(
    public readonly message: string,
    public readonly title: string,
    public readonly code: number | string
  ) {}

  public static fromHttpError<T>(): OperatorFunction<T, PlaceBetApiError | T> {
    return catchError((err: HttpErrorResponse) => {
      const errorMessage: string =
        err.error?.detail ??
        err.error?.message ??
        $localize`Ooups, something goes wrong! : Server Error: ${err.status}`;
      return of(new PlaceBetApiError(errorMessage, err.error?.title ?? '', err.error?.code ?? ''));
    });
  }

  public hasRatesChanged(): boolean {
    return this.code === 5001;
  }

  public hasNotEnoughMoney(): boolean {
    return this.code === 3001 || this.code === 5035;
  }

  public hasNoFundsInRealMoney(): boolean {
    return this.code === 101;
  }
}
