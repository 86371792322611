import { ChangeDetectorRef, inject, Injectable, OnDestroy } from '@angular/core';
import { TypedSubject } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/types';
import { asapScheduler, MonoTypeOperatorFunction, Observable, Subscription, tap } from 'rxjs';
import { ExtractedObservable, WorkerBlocChannels } from '../common/types';
import { WorkerBlocId } from '../worker-blocs.type';
import { BrowserSideBlocController } from './browser-side-bloc-controller';
import { UiReadyNotifier } from './ui-ready-notifier';
import { Unsubscribable } from './unsubscribable';

@Injectable()
export class UnsubscribableWithWorkerBloc<T, R extends WorkerBlocChannels> extends Unsubscribable implements OnDestroy {
  private _uniqueId!: string;
  private _workerBlocId!: WorkerBlocId;

  private _browserSideBlocController: BrowserSideBlocController<R> = inject(BrowserSideBlocController);
  protected _cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  // FOR UI READY NOTIFIER
  private _uiReadyNotifier: UiReadyNotifier = inject(UiReadyNotifier);
  private _readyWhenPropsSet: Array<keyof R['rx']> = [];
  private _readyWhenPropsReceived: Record<string, boolean | undefined> = {};
  private _isReady: boolean = false;

  protected _initWorkerBloc(workerBlocId: WorkerBlocId): void {
    this._workerBlocId = workerBlocId;
    this._uniqueId = BrowserSideBlocController.getId(workerBlocId);
    this._browserSideBlocController.sendCreateInstance(workerBlocId, this._uniqueId);
    asapScheduler.schedule(() => this._cdRef.detectChanges(), 1);
    this._sub = this._browserSideBlocController
      .workerBlocInitialized(this._workerBlocId, this._uniqueId)
      .subscribe(() => this._initializedBloc());
  }

  public init(_arg1?: unknown, _arg2?: unknown, _arg4?: unknown): void {}

  protected _initializedBloc(): void {}

  protected _setupReadyWhenPropsReceived(props: Array<keyof R['rx']>): void {
    this._readyWhenPropsSet = props;
    this._uiReadyNotifier.start(this._uniqueId);
  }

  private _checkUiReady(): void {
    if (this._isReady) {
      return;
    }
    if (this._readyWhenPropsSet.length === 0) {
      return;
    }
    const allIsReady: boolean = this._readyWhenPropsSet.every(
      (key: keyof R['rx']) => this._readyWhenPropsReceived[key as string]
    );
    if (allIsReady) {
      this._uiReadyNotifier.finish(this._uniqueId);
      this._isReady = true;
    }
  }

  protected _tapAndSetReadyProp<T>(key: keyof R['rx']): MonoTypeOperatorFunction<T> {
    return tap(() => {
      this._readyWhenPropsReceived[key as string] = true;
      this._checkUiReady();
    });
  }

  protected _set<K extends keyof T>(key: K) {
    return <V extends T[K]>(value: V): void => {
      const that: T = this as any as T;
      that[key] = value;
      this._cdRef.detectChanges();
      this._readyWhenPropsReceived[key as string] = true;
      this._checkUiReady();
    };
  }

  protected _setResolvedPromise<K extends keyof T, V extends T[K]>(key: K, promise: Promise<V>): void {
    promise.then((value: V) => {
      const that: T = this as any as T;
      that[key] = value;
      this._cdRef.detectChanges();
      this._readyWhenPropsReceived[key as string] = true;
      this._checkUiReady();
    });
  }

  protected _subTo<K extends keyof R['rx'], V extends ExtractedObservable<R['rx'][K]>>(channelRx: K): Observable<V> {
    return this._browserSideBlocController.subTo(this._workerBlocId, this._uniqueId, channelRx);
  }

  protected _subToAndSubscribe<K extends keyof R['rx'], Z extends keyof T, V extends ExtractedObservable<R['rx'][K]>>(
    channelRx: K & Z
  ): Subscription {
    // TODO: fix any
    return this._subTo<K, V>(channelRx).subscribe(this._set(channelRx as any));
  }

  protected _workerSubject<V extends TypedSubject<R['subjects'][K]>, K extends keyof R['subjects']>(subjectKey: K): V {
    return this._browserSideBlocController.workerSubject(this._workerBlocId, () => this._uniqueId, subjectKey);
  }

  protected _workerObservable<K extends keyof R['rx'], V extends ExtractedObservable<R['rx'][K]>>(
    channelRx: K
  ): Observable<V> {
    return this._browserSideBlocController.subTo(this._workerBlocId, this._uniqueId, channelRx);
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._browserSideBlocController.sendDestroyInstance(this._workerBlocId, this._uniqueId);
  }
}
