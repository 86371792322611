export class CmsDepositMethod {
  constructor(
    public readonly cpBackendId: number,
    public readonly name: string,
    public readonly cssClass: string,
    public readonly contentFormInfo: string | undefined,
    public readonly additionalDescHtml: string | undefined
  ) {}

  public static fromJson(json: Record<string, any>): CmsDepositMethod {
    return new CmsDepositMethod(
      json['id'],
      json['name'],
      json['cssClass'],
      json['contentFormInfo'] && json['contentFormInfo'] !== '' ? json['contentFormInfo'] : undefined,
      json['content'] && json['content'] !== '' ? json['content'] : undefined
    );
  }

  public static fromJsonArray(jsons: Record<string, any>[]): CmsDepositMethod[] {
    return jsons.map(CmsDepositMethod.fromJson);
  }
}
