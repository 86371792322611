import { DeviceBaseType } from '@bcf-vanilla-ts-v1-utilities/device-info/types';
import { extractDevice } from '@bcf-vanilla-ts-v1-utilities/device-info/utils';
import { Observable, distinctUntilChanged, fromEvent, map, merge, startWith, switchMap, timer } from 'rxjs';
import {
  initWorkerMessage,
  workerCookiesMessage,
  workerDeviceInfoMessage,
  workerIsUserActiveInAppMessage,
  workerUrlMessage
} from '../common/messages';
import { provideBrowserSideBlocController } from './browser-side-bloc-controller';
import { registerWorkerInstance } from './tokens';
import { WorkerWrapper } from './worker-wrapper';

function isUserActiveInApp(): Observable<boolean> {
  return merge(
    merge(fromEvent(document, 'click'), fromEvent(document, 'scroll')).pipe(
      startWith(undefined),
      map(() => true)
    ),
    merge(fromEvent(document, 'click'), fromEvent(document, 'scroll')).pipe(
      startWith(undefined),
      switchMap(() => timer(5 * 60 * 1000)),
      map(() => false)
    )
  ).pipe(distinctUntilChanged());
}

export function updateWorkerToolkitFromUi(
  worker: Worker | WorkerWrapper,
  document: Document,
  deviceKindBase: DeviceBaseType
): void {
  let previousCookies: string | undefined;
  let previousPathname: string | undefined;
  setInterval(() => {
    const currentCookies: string = document.cookie;
    if (previousCookies !== currentCookies) {
      worker.postMessage(workerCookiesMessage(document.cookie));
      previousCookies = currentCookies;
    }

    const currentPathname: string = document.location.pathname;
    if (previousPathname !== currentPathname) {
      worker.postMessage(workerUrlMessage(currentPathname, document.location.href));
      previousPathname = document.location.pathname;
    }
  }, 10);
  worker.postMessage(workerDeviceInfoMessage(extractDevice(deviceKindBase, window, navigator)));
  isUserActiveInApp().subscribe((isUserActiveInApp: boolean) =>
    worker.postMessage(workerIsUserActiveInAppMessage(isUserActiveInApp))
  );
}

export function provideWorker(worker: Worker, appRuntimeId: number, deviceKindBase: DeviceBaseType): void {
  worker.postMessage(initWorkerMessage(appRuntimeId, document.location.hostname));
  updateWorkerToolkitFromUi(worker, document, deviceKindBase);
  registerWorkerInstance(worker);
  provideBrowserSideBlocController().init();
}
