export class CmsRoute {
  constructor(
    public readonly path: string,
    public readonly urlToFetch: string,
    public readonly withMainFrame: boolean,
    public readonly getByKey?: boolean
  ) {}

  public static fromJson(json: Record<string, any>): CmsRoute {
    return new CmsRoute(json['path'], json['urlToFetch'], json['withMainFrame'], json['getByKey']);
  }

  public static fromJsonArray(jsons: Record<string, any>[]): CmsRoute[] {
    return jsons.map(CmsRoute.fromJson);
  }
}
