import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const WINDOW: InjectionToken<Window> = new InjectionToken<Window>('WindowToken');

export function provideBrowserWindow(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: WINDOW, useFactory: () => window }]);
}

export function provideServerWindow(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: WINDOW, useFactory: () => new Object() }]);
}
