import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IconsGcpLoader } from '@bcf-icons/svg/common';
import { identifier } from '@bcf-icons/svg/packages/icon-package-sb-letsbetmd-mobile-web';
import { LazyInitializatorInjector } from '@bcf-libs/app-utils/src/lib/utils';

@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  providers: [LazyInitializatorInjector]
})
export class AppComponent implements OnInit {
  @ViewChild('pushNotificationsViewAnchorRef', { static: true, read: ViewContainerRef })
  pushNotificationsViewAnchorRef!: ViewContainerRef;

  constructor(
    private _lazyInit: LazyInitializatorInjector,
    private _injector: Injector,
    private _iconsGcpLoader: IconsGcpLoader
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._initIcons();
    this._initPushNotifications();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (
          await import(
            /* webpackChunkName: "app-initializator" */ '@apps/projects/sportsbook/letsbetmd/mobile-web/src/app/app-initializator'
          )
        ).LazyInitializatorSbLetsbetmdMobileWeb
      )
      .init();
  }

  private async _initIcons(): Promise<void> {
    this._iconsGcpLoader.init(identifier);
  }

  private async _initPushNotifications(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/typedef
    const { PushNotificationsTwaComponent } = await import(
      /* webpackChunkName: "push-notifications-initializer" */ '@bcf-libs/ui/others/components/sportsbook/lvbetpl/mobile-web/src/lib/push-notifications/push-notifications-twa.component'
    );
    this._lazyInit.inject(this.pushNotificationsViewAnchorRef, PushNotificationsTwaComponent);
  }
}
