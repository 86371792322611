import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, mergeMap, retryWhen, throwError } from 'rxjs';

export function status0RetryInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    retryWhen((errors: Observable<HttpErrorResponse | unknown>) =>
      errors.pipe(
        mergeMap((error: HttpErrorResponse | unknown, _i: number) => {
          // temporary disabled due investigation: https://empsgourp.atlassian.net/browse/BCF-3744
          // const retryAttempt: number = i + 1;
          // // dont add more statuses like 403 or 401, it may crash some features
          // const statusCodeForRetry: number[] = [0, 504];
          // if (error instanceof HttpErrorResponse && statusCodeForRetry.includes(error.status) && retryAttempt < 4) {
          //   return timer(1500 * i);
          // }
          return throwError(error);
        })
      )
    )
  );
}
