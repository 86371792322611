import { InjectionToken } from '@angular/core';
import { OddsFormat, PrimaryColumnSettingsMode } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ODDS_FORMAT_DEFAULT: InjectionToken<OddsFormat> = new InjectionToken<OddsFormat>('oddsFormatDefault', {
  factory: () => 'decimal'
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const USA_KIND_STYLE_ODDS_ENABLED: InjectionToken<boolean> = new InjectionToken<boolean>(
  'UsaKindStyleOddsEnabled',
  {
    factory: () => false
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const LIVE_MATCH_IN_CARD_STYLE_ENABLED: InjectionToken<boolean> = new InjectionToken<boolean>(
  'LiveMatchInCardStyleEnabled',
  {
    factory: () => true
  }
);

// 1 = mobile
// 3 = web, terminal
export type OfferForColumns = 1 | 3;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const OFFER_FOR_COLUMNS: InjectionToken<OfferForColumns> = new InjectionToken<OfferForColumns>(
  'OfferForColumns',
  {
    factory: () => 1
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PRIMARY_COLUMN_SETTINGS_MODE: InjectionToken<PrimaryColumnSettingsMode> =
  new InjectionToken<PrimaryColumnSettingsMode>('PrimaryColumnSettingsMode', {
    factory: () => undefined
  });
