import { StatscoreVizuConfig } from '../types';

export const statscoreVizuConfig: StatscoreVizuConfig = {
  liveApiKeys: {
    match: '6448db48598daa1d99ed5587',
    stats: '61f92eb0f74c8d134361b00e'
  },
  preApiKeys: {
    match: '61938da5cc922aa7cc473070'
  }
};
