import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export abstract class ActivatedUrl {
  // name without $, like ActivatedRoute.params
  public get url(): Observable<string> {
    return EMPTY;
  }

  // name without $, like ActivatedRoute.params
  public get fullUrl(): Observable<string> {
    return EMPTY;
  }
}
