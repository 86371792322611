import { Component, ViewEncapsulation } from '@angular/core';
import { linkScss } from './link-scss';

@Component({
  selector: 'bcf-modal-main-container',
  template: '<ng-content></ng-content>',
  styleUrls: [linkScss],
  encapsulation: ViewEncapsulation.None
})
export class ModalMainContainerComponent {}
