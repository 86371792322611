/* eslint-disable @typescript-eslint/typedef */
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { PwaService } from '@bcf-v2-ui-shared/services/pwa.service';
import { device } from '@device/device';
import { buildSettings } from '@env/build-settings';
import { environment } from '@env/env';
import { environment as environmentSports } from '@env/env-sports';

export const basicInitDeps = [SharedSettings];
export const basicInit = (sharedSettings: SharedSettings) => async (): Promise<void> => {
  sharedSettings.environment = environment;
  sharedSettings.envSports = environmentSports;
  sharedSettings.device = device;
  sharedSettings.buildSettings = buildSettings;
  sharedSettings.setReady();
};

export const pwaInitDeps = [PwaService];
export const pwaInit = (pwaService: PwaService) => async (): Promise<void> => {
  pwaService.init();
};
