import { Injectable } from '@angular/core';
import { WorkerReady as VanillaTsWorkerReady } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/worker-ready';
import { WorkerWrapper } from './worker-wrapper';

@Injectable({
  providedIn: 'root'
})
export class WorkerReady extends VanillaTsWorkerReady {
  constructor(private _workerInner: WorkerWrapper) {
    super(_workerInner);
  }
}
