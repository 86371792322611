import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const HISTORY: InjectionToken<History> = new InjectionToken('HistoryToken');

export function provideBrowserHistory(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: HISTORY, useFactory: () => window.history }]);
}

export function provideServerHistory(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: HISTORY, useFactory: () => new Object() }]);
}
