import { BrowserPlatformLocation } from '@angular/common';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BrowserHistoryMemory {
  public historyUrl: string[] = [];

  public replaceUrl(url: string): void {
    if (this.historyUrl.length === 0) {
      this.historyUrl[0] = url;
    } else {
      this.historyUrl[this.historyUrl.length - 1] = url;
    }
  }

  public pushUrl(url: string): void {
    this.historyUrl = [...this.historyUrl, url];
  }

  public popUrl(): void {
    this.historyUrl = this.historyUrl.slice(0, -1);
  }

  public urlGo(relativePosition: number): void {
    if (relativePosition >= 0) {
      return;
    }
    const abs: number = Math.abs(relativePosition);
    for (let i: number = 0; i < abs; i++) {
      this.popUrl();
    }
  }
}

@Injectable({ providedIn: 'root' })
export class BrowserMemorizedPlatformLocation extends BrowserPlatformLocation {
  private _browserHistoryMemory: BrowserHistoryMemory = inject(BrowserHistoryMemory);

  public override pushState(state: any, title: string, url: string): void {
    super.pushState(state, title, url);
    this._browserHistoryMemory.pushUrl(url);
  }

  public override replaceState(state: any, title: string, url: string): void {
    super.replaceState(state, title, url);
    this._browserHistoryMemory.replaceUrl(url);
  }

  public override back(): void {
    super.back();
    this._browserHistoryMemory.popUrl();
  }

  public override historyGo(relativePosition: number = 0): void {
    super.historyGo(relativePosition);
    this._browserHistoryMemory.urlGo(relativePosition);
  }
}
