import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BuildSettings, Device, Environment, EnvironmentSports } from './types';

@Injectable({
  providedIn: 'root'
})
export class SharedSettings {
  private _environment!: Environment;
  private _envSports!: EnvironmentSports;
  private _device!: Device;
  private _buildSettings!: BuildSettings;

  private _ready$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _i18nReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public set environment(data: Environment) {
    if (this._environment) {
      throw new Error('you can setup environment just once');
    }
    this._environment = data;
  }

  public get environment(): Environment {
    return this._environment;
  }

  public set envSports(data: EnvironmentSports) {
    if (this._envSports) {
      throw new Error('you can setup environment-sports just once');
    }
    this._envSports = data;
  }

  public get envSports(): EnvironmentSports {
    return this._envSports;
  }

  public set device(data: Device) {
    if (this._device) {
      throw new Error('you can setup device settings just once');
    }
    this._device = data;
  }

  public get device(): Device {
    return this._device;
  }

  public set buildSettings(data: BuildSettings) {
    if (this._buildSettings) {
      throw new Error('you can setup build settings just once');
    }
    this._buildSettings = data;
  }
  public get buildSettings(): BuildSettings {
    return this._buildSettings;
  }

  public setReady(): void {
    this._ready$.next(true);
  }

  public setI18nReady(): void {
    this._i18nReady$.next(true);
  }

  public isReadyCold(): Observable<boolean> {
    return this._ready$.pipe(
      filter((state: boolean) => state === true),
      take(1)
    );
  }

  public isI18nReadyCold(): Observable<boolean> {
    return this._i18nReady$.pipe(
      filter((state: boolean) => state === true),
      take(1)
    );
  }
}
