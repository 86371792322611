import { Injectable } from '@angular/core';
import { WorkerSynchronizerContract } from '@bcf-vanilla-ts-v1-platforms/platform-worker/worker-synchronizer/worker-synchronizer-contract';
import { WorkerSynchronizerSpawner as VanillaTsWorkerSynchronizerSpawner } from '@bcf-vanilla-ts-v1-platforms/platform-worker/worker-synchronizer/worker-synchronizer-spawner';

@Injectable({ providedIn: 'root' })
export abstract class WorkerSynchronizerSpawner extends VanillaTsWorkerSynchronizerSpawner {
  constructor(protected override _workerSynchronizerMessenger: WorkerSynchronizerContract) {
    super(_workerSynchronizerMessenger);
  }
}
