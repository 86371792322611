import { Injectable } from '@angular/core';
import { workerLangMessage } from '@bcf-vanilla-ts-v1-platforms/platform-worker/common/messages';
import { WorkerWrapper } from './worker-wrapper';

@Injectable({
  providedIn: 'root'
})
export class WorkerToolkitUi {
  constructor(private _worker: WorkerWrapper) {}

  public updateLangOnWorker(lang: string): void {
    this._worker.postMessage(workerLangMessage(lang));
  }
}
