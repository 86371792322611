import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInAppActivity {
  public get isUserActiveInApp$(): Observable<boolean> {
    return EMPTY;
  }

  public get isUserNotActiveInApp$(): Observable<boolean> {
    return EMPTY;
  }
}
