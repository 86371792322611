import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { registerBcfAppId } from '@bcf-vanilla-ts-v1-configurators/tokens/app/token';
import { BCF_APP_ID, BCF_PRODUCTION_MODE, BCF_RELEASE_VERSION } from './token';
import { BcfAppId } from './types';

export function provideBcfAppId(bcfAppId: BcfAppId): EnvironmentProviders {
  registerBcfAppId(bcfAppId);
  return makeEnvironmentProviders([{ provide: BCF_APP_ID, useValue: bcfAppId }]);
}

export function provideBcfReleaseVersion(releaseVersion: string): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: BCF_RELEASE_VERSION, useValue: releaseVersion }]);
}

export function provideBcfProductionMode(isProduction: boolean): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: BCF_PRODUCTION_MODE, useValue: isProduction }]);
}
