import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { ActivatedUrl } from './activated-url/activated-url';
import { ActivatedUrlBrowser } from './activated-url/activated-url-browser';
import { UserInAppActivity } from './user-in-app-activity/user-in-app-activity';
import { UserInAppActivityBrowser } from './user-in-app-activity/user-in-app-activity-browser';

export * from './index-common';

export function providePlatformWorkerEnhanced(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ActivatedUrl,
      useClass: ActivatedUrlBrowser
    },
    {
      provide: UserInAppActivity,
      useClass: UserInAppActivityBrowser
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (activatedUrl: ActivatedUrlBrowser, userInAppActivity: UserInAppActivityBrowser) => () => {
        activatedUrl.init();
        userInAppActivity.init();
      },
      deps: [ActivatedUrl, UserInAppActivity],
      multi: true
    }
  ]);
}
