import { CookieConsentType } from '@bcf-v2-configurators/tokens/cookie-consent/types';
import { keys } from 'rambdax-v9';

type GTagConsentType = 'granted' | 'denied';
type GTagCookieConsentKeys =
  | 'ad_storage'
  | 'ad_user_data'
  | 'ad_personalization'
  | 'analytics_storage'
  | 'personalization_storage';
export type GTagCookieConsent = Record<string, GTagConsentType>;

export function extractCookies(cookiesStr: string): Record<string, string> {
  const container: Record<string, string> = {};

  const splitedOuter: string[] = cookiesStr.split(';');
  for (const innerStr of splitedOuter) {
    const splitedInner: string[] = innerStr.split('=');
    container[splitedInner[0].trim()] = splitedInner[1];
  }

  return container;
}

export function mapCookies(
  cookies: Record<string, string>,
  cookiesMap: Record<string, string>
): Record<string, string> {
  const container: Record<string, string> = { ...cookies };
  for (const key of keys(cookiesMap)) {
    if (cookies[key]) {
      container[key] = cookiesMap[key];
    }
  }
  return container;
}

/**
 * @see https://github.com/stevermeister/ngx-cookie-service/blob/master/projects/ngx-cookie-service/src/lib/cookie.service.ts
 */
export function setCookie(name: string, value: string, expires?: Date, path?: string, domain?: string): string {
  let cookieString: string = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';
  if (expires) {
    cookieString += 'expires=' + expires.toUTCString() + ';';
  }
  cookieString += 'path=' + (path ?? '/') + ';';
  if (domain) {
    cookieString += 'domain=' + domain + ';';
  }
  return cookieString;
}

export function mapCookiesConsentToGTagConsent(currentConsent: CookieConsentType[]): GTagCookieConsent {
  const consentMapGTag: Record<GTagCookieConsentKeys, CookieConsentType> = {
    ad_storage: 'Marketing',
    ad_user_data: 'Marketing',
    ad_personalization: 'Marketing',
    analytics_storage: 'Analytics',
    personalization_storage: 'Preferences'
  };

  const consentGTag: GTagCookieConsent = {};
  for (const [key, value] of Object.entries(consentMapGTag)) {
    consentGTag[key] = currentConsent.includes(value) ? 'granted' : 'denied';
  }

  return consentGTag;
}
