import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export type EnvBase = {
  brandName: string;
  availableLangs: string[];
  translationsBrandName: string;
  translationsAllowedBucketLangs: string[];
  translationsLangsMap?: Record<string, string>;
  cookiesMap?: Record<string, string>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_BASE: InjectionToken<EnvBase> = new InjectionToken<EnvBase>('EnvBase');

export function provideEnvBase(envBase: EnvBase): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: ENV_BASE, useValue: envBase }]);
}
