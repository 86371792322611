// import { defaultStackParser } from '@sentry/browser';
// import { parseStackFrames } from '@sentry/utils';
import { extractError, isString } from './extractors';
import { HttpCustomError } from './http-custom-error';

export type ErrorPrepared = {
  name: string;
  message?: string;
  stack?: string;
  url?: string;
  statusCode?: number;
};

export function getErrorPrepared(error: Error | undefined): ErrorPrepared | undefined {
  if (!error) {
    return undefined;
  }
  const extractedError: string | Error | HttpCustomError | undefined = extractError(error);
  if (extractedError instanceof HttpCustomError) {
    return extractedError;
  }
  if (!extractedError) {
    return undefined;
  }
  if (isString(extractedError)) {
    return {
      name: 'UnknownError',
      message: extractedError
    };
  }

  const stringified: string = JSON.stringify(extractedError);
  if (stringified === '{}' && extractedError.message) {
    return {
      name: extractedError.name,
      message: extractedError.message,
      stack: extractedError.stack
    };
  }

  return extractedError;
}
