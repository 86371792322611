import { Inject, Injectable } from '@angular/core';
import { AppCurrentLang } from '@bcf-shared-settings/settings/app-current-lang';
import { DeviceTypeInfo } from '@bcf-shared-settings/settings/device-type-info';
import { WindowWithApiVersion, resolveCmsApiUrl } from '@bcf-shared-settings/settings/env-utils';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';

function insertSlash(url: string): string {
  return url.slice(-1) === '/' ? '' : '/';
}

@Injectable({ providedIn: 'root' })
export class CmsApiConfig {
  constructor(
    private _settings: SharedSettings,
    private _deviceTypeInfo: DeviceTypeInfo,
    private _appCurrentLang: AppCurrentLang,
    @Inject(WINDOW) private _window: WindowWithApiVersion
  ) {}

  public jsonUrl(forceInsertDevicePrefix: boolean = false): string {
    let url: string = this._cmsApiResolve;
    if (this._settings.environment.cmsLangPrefix) {
      url += this._appCurrentLang.lang;
    }
    if (this._settings.environment.cmsBrandNamePrefix) {
      url += `${insertSlash(url)}${this._settings.environment.brandCmsName}`;
    }
    url += `${insertSlash(url)}json/`;
    if (this._settings.environment.cmsDevicePrefix || forceInsertDevicePrefix) {
      url += `${this._settings.device.overrideForCms ?? this._deviceTypeInfo.typeMappedToCms}-`;
    }
    return url;
  }

  public get insertSlashOrNot(): string {
    return this._settings.environment.cmsSlashOnTheEnd ? '/' : '';
  }

  private get _cmsApiResolve(): string {
    return resolveCmsApiUrl(
      this._settings.environment,
      this._settings.device,
      this._deviceTypeInfo.typeMappedToCms,
      this._window
    );
  }
}
