import { InjectionToken } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COMMISSION_ENABLED: InjectionToken<boolean> = new InjectionToken<boolean>('commissionEnabled', {
  factory: () => true
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const COMMON_TAX_PAYOUT_DETAIL_INFO: InjectionToken<string> = new InjectionToken<string>(
  'commonTaxPayoutDetailsInfo',
  {
    factory: () => ''
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CUSTOM_TAX_PAYOUT_DETAIL_INFO: InjectionToken<string> = new InjectionToken<string>(
  'customTaxPayoutDetailsInfo',
  {
    factory: () => ''
  }
);
