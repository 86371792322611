/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';
import { EditProfileAddressAvailableFieldsFn } from './types';

export const EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS: InjectionToken<string[]> = new InjectionToken<
  string[]
>('editProfilePrivacyAndNotificationsAvailableFields', {
  factory: () => []
});

export const EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS: InjectionToken<EditProfileAddressAvailableFieldsFn> =
  new InjectionToken<EditProfileAddressAvailableFieldsFn>('editProfileAddressAvailableFields', {
    factory: () => () => []
  });

export const EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_SHOW_CONTACT_SUPPORT_INFO: InjectionToken<boolean> =
  new InjectionToken<boolean>('editProfilePrivacyAndNotificationsShowContactSupportInfo', {
    factory: () => true
  });

export const EDIT_PROFILE_SHOW_CONTACT_SUPPORT_INFO: InjectionToken<boolean> = new InjectionToken<boolean>(
  'editProfileShowContactSupportInfo',
  {
    factory: () => true
  }
);
