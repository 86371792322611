import { Injectable } from '@angular/core';
import { WorkerWrapper } from '../browser/worker-wrapper';
import { WorkerSynchronizerSpawner } from './worker-synchronizer-spawner';

@Injectable({ providedIn: 'root' })
export class WorkerSynchronizerSpawnerBrowser extends WorkerSynchronizerSpawner {
  constructor(private _workerWrapper: WorkerWrapper) {
    super(_workerWrapper);
  }
}
