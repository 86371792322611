import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { filterNavigationEnd } from '@bcf-v2-shared/misc/rx-operators/filter-navigation-end';
import { Observable, distinctUntilChanged, map, startWith } from 'rxjs';
import { ActivatedUrl } from './activated-url';
import { createFullUrlChannel, createUrlChannel } from './utils';

type LocationKind = {
  origin: string;
  pathname: string;
  search: string;
};

function mapToUrlOriginWithPathname(location: LocationKind): string {
  return `${location.origin}${location.pathname}${location.search}`;
}

@Injectable({ providedIn: 'root' })
export class ActivatedUrlBrowser implements ActivatedUrl {
  private _urlChannel: WorkerSynchronizer<string> = createUrlChannel(this._workerSynchronizerSpawner);
  private _fullUrlChannel: WorkerSynchronizer<string> = createFullUrlChannel(this._workerSynchronizerSpawner);

  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private _workerSynchronizerSpawner: WorkerSynchronizerSpawner
  ) {}

  public init(): void {
    this.url.subscribe((url: string) => this._urlChannel.next(url));
    this.fullUrl.subscribe((fullUrl: string) => this._fullUrlChannel.next(fullUrl));
  }

  // name without $, like ActivatedRoute.params
  public get url(): Observable<string> {
    return this._router.events.pipe(
      filterNavigationEnd,
      startWith(undefined),
      map(() => this._router.url.split('#')[0].split('?')[0]),
      distinctUntilChanged()
    );
  }

  // name without $, like ActivatedRoute.params
  public get fullUrl(): Observable<string> {
    return this._router.events.pipe(
      filterNavigationEnd,
      startWith(undefined),
      map(() => this._document.location),
      map(mapToUrlOriginWithPathname)
    );
  }
}
