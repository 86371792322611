/**
 * LEGEND
 * 1. loginStatus:
 *  a. true -> visible when user is logged in
 *  b. false -> visible when user is not logged in
 *  c. undefined -> dont check this condition, visible when user is logged in or not
 *
 * 2. userRoles -> visible for roles
 * 3. usedBonusesCode -> visible for bonuses codes
 * 4. depositCount -> visible for example [1,2,3,4,5], will be visible when user made deposit count $1 >= 1 && $1 <= 5
 * 5. accessMode -> true: allowed, false: restricted, undefined: unrestricted
 */

export type CmsRestrictionConfigs = {
  readonly accessMode: boolean;
  readonly loginStatus: boolean | undefined;
  readonly userRoles: string[] | undefined;
  readonly usedBonusesCode: string[] | undefined;
  readonly depositCount: number[] | undefined;
};

export function createCmsRestrictionConfigsFromJsonHttp(json: Record<string, any>): CmsRestrictionConfigs {
  const stateMap: Record<string, boolean | undefined> = {
    both: undefined,
    active: true,
    inactive: false
  };

  return {
    accessMode: json['accessMode'] ?? false,
    loginStatus: stateMap[json['loginStatus']] ?? undefined,
    userRoles: json['userRoles'] ?? undefined,
    usedBonusesCode: json['usedBonusesCode'] ?? undefined,
    depositCount: json['depositCount'] ?? undefined
  };
}

export function createTestingCmsRestrictionConfigsPartial(
  partial: Partial<CmsRestrictionConfigs>
): CmsRestrictionConfigs {
  return {
    ...partial
  } as CmsRestrictionConfigs;
}
