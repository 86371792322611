import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

const brandName: string = 'letsbetmd';
// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  productType: ProductType.sportsbook,
  brandI18Name: brandName,
  brandCmsName: 'letsbetmd',
  brandName: 'LetsBetMd',
  langs: ['en'],
  langsI18nMap: {
    en: 'en_US'
  },
  currencyFormatMap: {
    USD: '$'
  },
  cmsHeadlessEnabled: true,
  ssrDefaultCacheTime: 30,
  geocomplyWebServiceUrl: 'https://us3-stg-oobee-v2.geocomply.net/'
};
