import { DOCUMENT, isPlatformServer } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';

import { Observable, fromEvent, map, mapTo, merge, mergeMap, startWith, timer } from 'rxjs';

function visibilityChangeEvent(document: Document): string {
  if (typeof document.hidden !== 'undefined') {
    return 'visibilitychange';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return 'visibilitychange';
}

function documentHiddenProp(document: Document): string {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return 'hidden';
}

function getPageActiveState(document: Document): Observable<boolean> {
  // timer because we dont need this at startup - perf
  const documentAny: any = document;
  return timer(5000).pipe(
    mergeMap(() =>
      merge(
        fromEvent(document, visibilityChangeEvent(document)).pipe(
          map(() => !documentAny[documentHiddenProp(document)])
        ),
        fromEvent(document, 'pause').pipe(mapTo(false)),
        fromEvent(document, 'resume').pipe(mapTo(true))
      )
    ),
    startWith(true)
  );
}

export function provideAppVisibilityBrowserProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory:
        (document: Document, platformId: string, workerSynchronizerSpawner: WorkerSynchronizerSpawner) => () => {
          if (isPlatformServer(platformId)) {
            return;
          }
          const appVisibilityChannel: WorkerSynchronizer<boolean> =
            workerSynchronizerSpawner.spawn('appVisibilityChannel');

          getPageActiveState(document).subscribe((state: boolean) => appVisibilityChannel.next(state));
        },
      deps: [DOCUMENT, PLATFORM_ID, WorkerSynchronizerSpawner],
      multi: true
    }
  ]);
}
