import { DOCUMENT, isPlatformServer } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { NAVIGATOR } from '@bcf-v2-platforms/platform-apis/navigator-provider';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import {
  DeviceBaseType,
  DeviceInfoPayload,
  NavigatorWithUserAgent,
  WindowForDeviceInfo
} from '@bcf-vanilla-ts-v1-utilities/device-info/types';
import { extractDevice } from '@bcf-vanilla-ts-v1-utilities/device-info/utils';
import { DEVICE_BASE_KIND, DEVICE_INFO } from './token';

/** this is needed for fastify: some browser send wrong user-agent from webWorker,
 * for example open firefox with mobile mode safari, and check requests from worker
 */
export function setDeviceKind(deviceKindBase: DeviceBaseType, document: Document): void {
  let cookieString: string = encodeURIComponent('bcfDeviceKindBase') + '=' + encodeURIComponent(deviceKindBase) + ';';
  const expiryDays: number = 1;
  const dateExpires: Date = new Date(new Date().getTime() + expiryDays * 1000 * 60 * 60 * 24);
  cookieString += 'expires=' + dateExpires.toUTCString() + ';';
  cookieString += 'path=' + '/' + ';';
  document.cookie = cookieString;
}

export function provideDeviceInfoBrowserProvider(deviceKindBase: DeviceBaseType): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DEVICE_BASE_KIND,
      useValue: deviceKindBase
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          platformId: string,
          window: WindowForDeviceInfo,
          document: Document,
          navigator: NavigatorWithUserAgent,
          workerSynchronizerSpawner: WorkerSynchronizerSpawner
        ) =>
        () => {
          if (isPlatformServer(platformId)) {
            return;
          }
          setDeviceKind(deviceKindBase, document);
          const deviceInfoPayload: DeviceInfoPayload = extractDevice(deviceKindBase, window, navigator);
          const deviceInfoPayloadChannel: WorkerSynchronizer<DeviceInfoPayload> =
            workerSynchronizerSpawner.spawn('deviceInfoPayload');
          deviceInfoPayloadChannel.next(deviceInfoPayload);
        },
      deps: [
        PLATFORM_ID,
        [new Inject(WINDOW)],
        [new Inject(DOCUMENT)],
        [new Inject(NAVIGATOR)],
        WorkerSynchronizerSpawner
      ],
      multi: true
    },
    {
      provide: DEVICE_INFO,
      useFactory: (window: WindowForDeviceInfo, navigator: NavigatorWithUserAgent) =>
        extractDevice(deviceKindBase, window, navigator),
      deps: [[new Inject(WINDOW)], [new Inject(NAVIGATOR)]]
    }
  ]);
}
