import { ViewportScroller } from '@angular/common';
import { Injectable, NgZone, Provider, inject } from '@angular/core';
import {
  InMemoryScrollingOptions,
  NavigationEnd,
  NavigationSkipped,
  NavigationSkippedCode,
  NavigationStart,
  RouterEvent,
  UrlSerializer,
  ɵNavigationTransitions,
  ɵROUTER_SCROLLER,
  ɵRouterScroller
} from '@angular/router';
import { Subscription, filter } from 'rxjs';

// this is copy from @angular/router
export function provideNoQueryParamRouterScroller(): Provider {
  return {
    provide: ɵROUTER_SCROLLER,
    useFactory: (): NoQueryParamRouterScroller => {
      const viewportScroller: ViewportScroller = inject(ViewportScroller);
      const zone: NgZone = inject(NgZone);
      const transitions: ɵNavigationTransitions = inject(ɵNavigationTransitions);
      const urlSerializer: UrlSerializer = inject(UrlSerializer);
      const options: InMemoryScrollingOptions = {
        scrollPositionRestoration: 'top'
      };
      return new NoQueryParamRouterScroller(urlSerializer, transitions, viewportScroller, zone, options);
    }
  };
}

type FilteredRouterEvent = NavigationStart | NavigationEnd | NavigationSkipped;

function isCorrectInstanceEvent(routerEvent: RouterEvent): routerEvent is FilteredRouterEvent {
  if (routerEvent instanceof NavigationStart) {
    return true;
  }
  if (routerEvent instanceof NavigationEnd) {
    return true;
  }
  if (routerEvent instanceof NavigationSkipped) {
    return true;
  }
  return false;
}

// this is copy from @angular/router
@Injectable()
export class NoQueryParamRouterScroller extends ɵRouterScroller {
  private _previousScrollUrl: string | undefined;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected override createScrollEvents(): Subscription {
    // only added filter and distinctUntilChanged
    return this.transitions.events
      .pipe(filter(isCorrectInstanceEvent))
      .subscribe((routerEvent: FilteredRouterEvent) => {
        if (routerEvent instanceof NavigationStart) {
          // store the scroll position of the current stable navigations.
          this.store[this.lastId] = this.viewportScroller.getScrollPosition();
          this.lastSource = routerEvent.navigationTrigger;
          this.restoredId = routerEvent.restoredState ? routerEvent.restoredState.navigationId : 0;
        } else if (routerEvent instanceof NavigationEnd) {
          this.lastId = routerEvent.id;
          this._scheduleScrollEventIfCan(
            routerEvent.urlAfterRedirects,
            routerEvent,
            this.urlSerializer.parse(routerEvent.urlAfterRedirects).fragment
          );
        } else if (
          routerEvent instanceof NavigationSkipped &&
          routerEvent.code === NavigationSkippedCode.IgnoredSameUrlNavigation
        ) {
          this.lastSource = undefined;
          this.restoredId = 0;
          this._scheduleScrollEventIfCan(
            routerEvent.url,
            routerEvent,
            this.urlSerializer.parse(routerEvent.url).fragment
          );
        }
      });
  }

  private _scheduleScrollEventIfCan(
    urlToCheck: string,
    routerEvent: NavigationEnd | NavigationSkipped,
    anchor: string | null
  ): void {
    // skip on query params
    if (urlToCheck.includes('?')) {
      return;
    }
    if (urlToCheck === this._previousScrollUrl) {
      return;
    }
    // setTimeout = sometimes app scroll top when there is no content, then content appear and the page is not scrolled top
    setTimeout(() => {
      this.scheduleScrollEvent(routerEvent, anchor);
    }, 100);
    this._previousScrollUrl = urlToCheck;
  }
}
