import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { Unsubscribable } from './unsubscribable';

@Injectable()
export abstract class UnsubscribableWithSetable<T> extends Unsubscribable {
  protected _cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  protected _set<K extends keyof T>(key: K) {
    return <V extends T[K]>(value: V): void => {
      const that: T = this as any as T;
      that[key] = value;
      this._cdRef.detectChanges();
    };
  }

  protected _setResolvedPromise<K extends keyof T, V extends T[K]>(key: K, promise: Promise<V>): void {
    promise.then((value: V) => {
      const that: T = this as any as T;
      that[key] = value;
      this._cdRef.detectChanges();
    });
  }

  public updateUiVar<K extends keyof this, V extends this[K]>(key: K, value: V): void {
    this[key] = value;
    this._cdRef.detectChanges();
  }
}
