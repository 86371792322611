import { HttpErrorResponse } from '@angular/common/http';
import { isHttpError } from '@bcf-vanilla-ts-v1-api-access/common/http-client';
import { HttpCustomError } from './http-custom-error';

export type ErrorCandidate = {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
};

const objectToString: () => string = Object.prototype.toString;

function isBuiltin(wat: unknown, className: string): boolean {
  return objectToString.call(wat) === `[object ${className}]`;
}

export function isString(wat: unknown): wat is string {
  return isBuiltin(wat, 'String');
}

function isErrorOrErrorLikeObject(value: unknown): value is Error {
  if (value instanceof Error) {
    return true;
  }

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const candidate: ErrorCandidate = value as ErrorCandidate;

  return (
    isString(candidate.name) &&
    isString(candidate.message) &&
    (undefined === candidate.stack || isString(candidate.stack))
  );
}

export function extractError(error: Error): Error | HttpCustomError | undefined | string {
  if (error instanceof HttpErrorResponse) {
    return HttpCustomError.fromHttpErrorResponse(error);
  }
  if (isHttpError(error)) {
    return HttpCustomError.fromVanillaTsHttpError(error);
  }
  if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
    return error;
  }
  return undefined;
}
