export class CmsRegistrationWizardData {
  constructor(
    public readonly checkboxData: Record<string, string>,
    public readonly completeHtml: string,
    public readonly completeQuickHtml: string,
    public readonly completeSmsHtml: string,
    public readonly footerHtml: string,
    public readonly promoImage:
      | {
          redirectUrl: string;
          cssClass: string;
        }
      | undefined,
    public readonly internalModalsHtml: Record<
      string,
      {
        cssClass: string;
        title: string;
        html: string;
      }
    >
  ) {}

  public static fromJson(json: Record<string, any>): CmsRegistrationWizardData {
    return new CmsRegistrationWizardData(
      json['checkboxTerms'],
      json['completeFull'],
      json['complete'],
      json['completeSms'],
      json['dataProtector'],
      json['promoInfo'] ?? undefined,
      json['internalModalsHtml']
    );
  }
}
