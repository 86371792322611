import { LadeskChatConfig } from '../types';

export const ladeskChatConfig: LadeskChatConfig = {
  perLangConfig: {
    en: {
      chatId: '5aee2v6x',
      chatIdForLoggedInUser: 'ufc3l46j'
    }
  },
  scriptUrl: 'https://letsbetmd.ladesk.com/scripts/track.js'
};
