import * as R from 'rambdax';

type CmsBreadcrumbConfig = {
  firstTitle: string;
  firstIcon: string;
  secondTitle?: string;
  secondIcon?: string;
};

export class CmsExternalPage {
  constructor(
    public readonly byKeyMode: boolean,
    public readonly content?: string,
    public readonly breadcrumb?: CmsBreadcrumbConfig,
    public readonly scripts?: string[],
    public readonly multiContent?: {
      [key: string]: {
        content: string;
        breadcrumb?: CmsBreadcrumbConfig;
        scripts: string;
      };
    }
  ) {}

  public static fromJson(json: Record<string, any>): CmsExternalPage {
    const byKeyMode: boolean = !Boolean(json['content']);
    return new CmsExternalPage(
      byKeyMode,
      json['content'],
      json['breadcrumb'],
      json['scripts'],
      byKeyMode
        ? R.piped(
            R.keys(json),
            R.reduce(
              (acc: Record<string, any>, key: string) =>
                R.assoc(
                  key,
                  {
                    content: json[key].content,
                    breadcrumb: json[key].breadcrumb,
                    scripts: json[key].scripts
                  },
                  acc
                ),
              {}
            )
          )
        : undefined
    );
  }
}
