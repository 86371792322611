import { Inject, Injectable } from '@angular/core';
import { BCF_APP_ID } from '@bcf-v2-configurators/tokens/app/token';
import { BcfAppId } from '@bcf-v2-configurators/tokens/app/types';
import { CurrentLang } from '@bcf-v2-utilities/current-lang/current-lang';
import { isDefined } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/is-defined';
import { UseStore, clear, createStore, del, get, getMany, set, setMany, values } from 'idb-keyval';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IndexdbStorage {
  private _didUpdate$: Subject<void> = new Subject<void>();

  constructor(
    private _currentLang: CurrentLang,
    @Inject(BCF_APP_ID) private _bcfAppId: BcfAppId
  ) {}

  public async createStore(storeName: string): Promise<UseStore> {
    return createStore(`${this._bcfAppId}:${await this._currentLang.getApiLang()}:${storeName}`, storeName);
  }

  public async set<T>(key: IDBValidKey, value: T, store?: UseStore): Promise<void> {
    await set(key, value, store);
    this._didUpdate$.next(undefined);
  }

  public async delete(key: IDBValidKey, store?: UseStore): Promise<void> {
    await del(key, store);
    this._didUpdate$.next(undefined);
  }

  public async clear(store?: UseStore): Promise<void> {
    await clear(store);
    this._didUpdate$.next(undefined);
  }

  public get<T>(key: IDBValidKey, store?: UseStore): Promise<T | undefined> {
    return get(key, store);
  }

  public async getMany<T>(keys: IDBValidKey[], store?: UseStore): Promise<T[]> {
    const results: (T | undefined)[] = await getMany(keys, store);
    return results.filter(isDefined);
  }

  public getAllValues<T>(store?: UseStore): Promise<T[]> {
    return values(store);
  }

  public async setMany<T>(entries: [IDBValidKey, T][], store?: UseStore): Promise<void> {
    await setMany(entries, store);
    this._didUpdate$.next(undefined);
  }

  public didUpdated(): Observable<void> {
    return this._didUpdate$.asObservable();
  }
}
