import { DiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { WorkerBlocRegistry } from '../common/types';
import { WorkerBlocId } from '../worker-blocs.type';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WORKER_BLOCS_REGISTRY: DiToken<WorkerBlocRegistry[]> = new DiToken<WorkerBlocRegistry[]>(
  'workerBlocsRegistry'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const WORKER_BLOCS_REGISTRY_WHITELIST: DiToken<WorkerBlocId[]> = new DiToken<WorkerBlocId[]>(
  'workerBlocsRegistryWhitelist'
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IS_WORKER: DiToken<boolean> = new DiToken<boolean>('isWorker');
