import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Cookies } from '@bcf-v2-utilities/cookies-provider/cookies';
import { Observable, OperatorFunction, catchError, from, mergeMap, throwError } from 'rxjs';

function handleError(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  cookies: Cookies,
  counter: number = 0
): OperatorFunction<HttpEvent<unknown>, HttpEvent<unknown>> {
  return catchError((error: HttpErrorResponse | unknown) => {
    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.detail &&
      error.error.detail.includes('CSRF') &&
      counter < 4
    ) {
      return from(cookies.getCsrfToken()).pipe(
        mergeMap((csrfToken: string) =>
          next(
            req.clone({
              headers: req.headers.set('x-csrftoken', csrfToken)
            })
          )
        ),
        handleError(req, next, cookies, counter + 1)
      );
    }
    return throwError(error);
  });
}

export function incorrectCsrfTokenRetryInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const cookies: Cookies = inject(Cookies);
  return next(req).pipe(handleError(req, next, cookies));
}
