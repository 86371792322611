import { Injectable } from '@angular/core';
import { BrowserSideBlocController as VanillaTsBrowserSideBlocController } from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/browser-side-bloc-controller';
import { WorkerBlocChannels } from '../common/types';
import { WorkerReady } from './worker-ready';
import { WorkerWrapper } from './worker-wrapper';

@Injectable({ providedIn: 'root' })
export class BrowserSideBlocController<R extends WorkerBlocChannels> extends VanillaTsBrowserSideBlocController<R> {
  constructor(
    protected override _worker: WorkerWrapper,
    protected override _workerReady: WorkerReady
  ) {
    super(_worker, _workerReady);
  }
}
