import { EditProfileAddressAvailableFieldsFn } from '../types';

export const editProfilePrivacyAndNotificationsAvailableFields: string[] = [];

export const editProfileAddressAvailableFieldsFn: EditProfileAddressAvailableFieldsFn = () => [
  { type: 'select', name: 'state', formName: 'state', placeholder: $localize`state`, isReadonly: false },
  { type: 'text', name: 'firstAddress', formName: 'address_1', placeholder: $localize`address`, isReadonly: false },
  { type: 'text', name: 'city', formName: 'city', placeholder: $localize`city`, isReadonly: false },
  { type: 'text', name: 'postalCode', formName: 'postal_code', placeholder: $localize`post code`, isReadonly: false },
  { type: 'select', name: 'language', formName: 'language', placeholder: $localize`language`, isReadonly: false },
  {
    type: 'text',
    name: 'nationalIdNumber',
    formName: 'national_id_number',
    placeholder: $localize`national id number`,
    isReadonly: false
  }
];
