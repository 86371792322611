import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { setReleaseVersion } from '@bcf-v2-utilities/app-utils/set-release-version';
import { environment } from '@env/env';
import { releaseVersion } from '@env/release-version';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

setReleaseVersion(releaseVersion, document);
if (environment.production) {
  enableProdMode();
}
// setTimeout = reduce total blocking time
setTimeout(() => {
  bootstrapApplication(AppComponent, appConfig).catch((err: any) => console.error(err));
}, 1);
