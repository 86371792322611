import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, Inject, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { AppCurrentLang } from '@bcf-shared-settings/settings/app-current-lang';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { IndexdbStorage } from '@bcf-v2-utilities/indexdb-storage/indexdb-storage';
import { UseStore } from 'idb-keyval';
import { lastValueFrom } from 'rxjs';
import { createAppId, getTranslationsUrl } from './common';

const idbI18nTranslationsKey: string = 'i18n-translations';

async function fetchTranslationsFromUrl(httpClient: HttpClient, appId: string): Promise<Record<string, any>> {
  const translationsUrl: string = getTranslationsUrl(appId);

  type TranslationData = {
    [hashKey: string]: {
      translated: string;
      updatedAt: string; // formatted as "14-02-2022"
    };
  };

  const translations: TranslationData = await lastValueFrom(httpClient.get<TranslationData>(translationsUrl));
  const container: Record<string, string> = {};
  for (const [key, value] of Object.entries(translations)) {
    container[key] = value.translated;
  }

  return container;
}

async function updateTranslationsIdb(
  httpClient: HttpClient,
  indexdbStorage: IndexdbStorage,
  appId: string,
  store: UseStore
): Promise<Record<string, any>> {
  try {
    const fetchedTranslations: Record<string, string> = await fetchTranslationsFromUrl(httpClient, appId);
    indexdbStorage.set(idbI18nTranslationsKey, fetchedTranslations, store);
    return fetchedTranslations;
  } catch (err) {
    return {};
  }
}

async function getTranslations(
  httpClient: HttpClient,
  indexdbStorage: IndexdbStorage,
  appId: string
): Promise<Record<string, any>> {
  const store: UseStore = await indexdbStorage.createStore('i18n-translations');

  const translationsFromIdb: Record<string, string> | undefined = await indexdbStorage.get<Record<string, string>>(
    idbI18nTranslationsKey,
    store
  );
  if (translationsFromIdb) {
    updateTranslationsIdb(httpClient, indexdbStorage, appId, store);
    return translationsFromIdb;
  }
  return await updateTranslationsIdb(httpClient, indexdbStorage, appId, store);
}

export function provideI18nTranslations(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          platformId: string,
          httpClient: HttpClient,
          indexdbStorage: IndexdbStorage,
          sharedSettings: SharedSettings,
          appCurrentLang: AppCurrentLang,
          workerSynchronizerSpawner: WorkerSynchronizerSpawner
        ) =>
        async () => {
          const bcfTranslationsChannel: WorkerSynchronizer<Record<string, string>> =
            workerSynchronizerSpawner.spawn('bcfTranslations');

          const lang: string = appCurrentLang.langI18n;
          if (lang === 'en') {
            sharedSettings.setI18nReady();
            bcfTranslationsChannel.next({});
            return;
          }

          const appId: string = createAppId(
            sharedSettings.environment.productType,
            sharedSettings.environment.brandI18Name,
            sharedSettings.device.type,
            lang
          );

          const translations: Record<string, string> = await getTranslations(httpClient, indexdbStorage, appId);

          try {
            bcfTranslationsChannel.next(translations);
            loadTranslations(translations);
            if (isPlatformBrowser(platformId)) {
              console.log('translations-bcf', translations);
            } else {
              console.log('translations-bcf', Object.keys(translations).length);
            }
          } catch (err) {
            bcfTranslationsChannel.next({});
            console.error('PANIC!: I18N failed');
            console.error(err);
          }
          sharedSettings.setI18nReady();
        },
      deps: [
        [new Inject(PLATFORM_ID)],
        HttpClient,
        IndexdbStorage,
        SharedSettings,
        AppCurrentLang,
        WorkerSynchronizerSpawner
      ],
      multi: true
    }
  ]);
}
