export function domainNumberStrExtractor(hostname: string): string {
  if (hostname.includes('testowaplatforma123.net')) {
    return '';
  }
  const host: string = hostname;
  let version: string = '';
  for (const char of host) {
    if (!isNaN(Number(char))) {
      version += char;
    }
  }
  return version;
}
