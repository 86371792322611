import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { Observable, ReplaySubject, first, map, race } from 'rxjs';
import { BrowserSideMessage } from '../common/messages';
import { WorkerWrapper, provideWorkerWrapper } from './worker-wrapper';

export class WorkerReady {
  private _isReadyInternal$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(private _worker: WorkerWrapper) {}

  public get isReady$(): Observable<void> {
    return race(
      this._isReadyInternal$,
      this._worker.getMessage<BrowserSideMessage>().pipe(
        first((message: BrowserSideMessage) => message?.action === 'workerReady'),
        map(() => undefined)
      )
    );
  }

  public makeReady(): void {
    this._isReadyInternal$.next(undefined);
  }
}

export function provideWorkerReady(): WorkerReady {
  return provideSingleton(WorkerReady, () => new WorkerReady(provideWorkerWrapper()));
}
