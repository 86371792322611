import { Injectable } from '@angular/core';
import {
  UiReadyNotifier as VanillaTsUiReadyNotifier,
  provideUiReadyNotifier
} from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/ui-ready-notifier';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiReadyNotifier {
  private _vanillaTsUiReadyNotifier: VanillaTsUiReadyNotifier = provideUiReadyNotifier();

  public startIdUpdate$: Subject<string> = this._vanillaTsUiReadyNotifier.startIdUpdate$;
  public finishIdUpdate$: Subject<string> = this._vanillaTsUiReadyNotifier.finishIdUpdate$;
  public isAnySetup$: ReplaySubject<boolean> = this._vanillaTsUiReadyNotifier.isAnySetup$;

  public start(id: string): void {
    this._vanillaTsUiReadyNotifier.start(id);
  }

  public finish(id: string): void {
    this._vanillaTsUiReadyNotifier.finish(id);
  }
}
