import { ComponentFactory, ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TerminalFakeApi } from '@bcf-shared-settings/settings/terminal-fake-api';
import { transferRxNext } from '@bcf-vanilla-ts-v1-shared/misc/rx-helpers/transfer-rx-next';
import { map, pluck } from 'rxjs';

export function initFakeApi(terminalFakeApi: TerminalFakeApi, activatedRoute: ActivatedRoute): void {
  activatedRoute.queryParams
    .pipe(pluck('fakeApi'), map(Boolean))
    .subscribe(transferRxNext(terminalFakeApi.fakeApiEnabled$));
}

@Injectable()
export class LazyInitializatorInjector {
  constructor(private _componentFactoryResolver: ComponentFactoryResolver) {}

  public inject<T>(anchorView: ViewContainerRef, component: Type<T>): void {
    const componentFactory: ComponentFactory<T> = this._componentFactoryResolver.resolveComponentFactory(component);
    anchorView.createComponent(componentFactory).changeDetectorRef.detectChanges();
  }
}
