import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { SharedSettings } from './shared-settings';
import { UNIVERSAL_LANG } from './tokens';

type WindowLike = {
  location?: {
    pathname?: string;
  };
};

const defaultLangsI18nMap: Record<string, string> = {
  br: 'pt_BR',
  sr: 'me'
};

export function getLangI18n(lang: string, langsI18nMap: Record<string, any> | undefined): string {
  return langsI18nMap?.[lang] ?? defaultLangsI18nMap[lang] ?? lang;
}

// AppCurrentLangFromUrl -> need for energycasino, they have langs like en-ez
@Injectable({
  providedIn: 'root'
})
export class AppCurrentLangFromUrl {
  constructor(
    @Inject(WINDOW) private _window: WindowLike,
    private _sharedSettings: SharedSettings
  ) {}

  public get lang(): string {
    const langFromUrl: string | undefined = this._window.location?.pathname?.split('/')?.[1];
    if (langFromUrl && langFromUrl.length > 0) {
      return langFromUrl;
    }
    return 'en';
  }

  public get langI18n(): string {
    const lang: string = this.lang;
    return this._sharedSettings.environment.langsI18nMap?.[lang] ?? defaultLangsI18nMap[lang] ?? lang;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppCurrentLang {
  private _langDetermined?: string;

  constructor(
    private _sharedSettings: SharedSettings,
    @Inject(WINDOW) private _window: WindowLike,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: string,
    @Optional() @Inject(UNIVERSAL_LANG) private _universalLang?: string
  ) {}

  public get lang(): string {
    if (isPlatformServer(this._platformId) && this._universalLang) {
      return this._universalLang;
    }
    if (this._langDetermined) {
      return this._langDetermined;
    }
    const locationFromEnv: string | undefined = this._sharedSettings.environment.langs.find((lang: string) =>
      this._window.location?.pathname?.split('/')?.includes(lang)
    );
    const locationFromTag: string | undefined = this._document.documentElement.lang;
    let selectedLang: string = locationFromEnv || locationFromTag || 'en';
    if (selectedLang === 'me') {
      selectedLang = 'sr';
    }
    this._langDetermined = selectedLang;
    return this._langDetermined;
  }

  public get langHeadless(): string {
    const langsHeadless: Record<string, string> = {
      br: 'pt'
    };
    return langsHeadless[this.lang] ?? this.lang;
  }

  public get langI18n(): string {
    return getLangI18n(this.lang, this._sharedSettings.environment.langsI18nMap);
  }
}
