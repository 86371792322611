/* eslint-disable @typescript-eslint/typedef */
import { LoadChildrenCallback, Router } from '@angular/router';
import { CmsRepository } from '@bcf-logic-api-access/repository/cms-repository';
import { CmsRoute } from '@bcf-logic-shared/models/cms-route';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import { lastValueFrom } from 'rxjs';

export const routesWithCmsInitDeps = [SharedSettings, Router, CmsRepository];

export const routesWithCmsInit =
  (loadChild: LoadChildrenCallback) =>
  (sharedSettings: SharedSettings, router: Router, cmsRepository: CmsRepository) =>
  async (): Promise<void> => {
    await lastValueFrom(sharedSettings.isI18nReadyCold());
    await lastValueFrom(sharedSettings.isReadyCold());
    const cmsRoutes: CmsRoute[] = await cmsRepository.getRoutesConfig();
    router.resetConfig([
      ...router.config,
      ...cmsRoutes.map((route: CmsRoute) => ({
        path: route.path,
        loadChildren: loadChild,
        data: {
          urlToFetch: route.urlToFetch,
          withMainFrame: route.withMainFrame,
          getByKey: route.getByKey
        }
      }))
    ]);
    router.initialNavigation();
  };

export const routerInitAfterEnvSetupDeps = [Router, SharedSettings];

export const routerInitAfterEnvSetup = (router: Router, sharedSettings: SharedSettings) => async (): Promise<void> => {
  await lastValueFrom(sharedSettings.isI18nReadyCold());
  await lastValueFrom(sharedSettings.isReadyCold());
  router.initialNavigation();
};
