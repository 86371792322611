import { inject, Injectable } from '@angular/core';
import { NavigationBehaviorOptions, Router, UrlTree } from '@angular/router';
import { BcfRouterRedirectorNoop } from './bcf-router-redirector-noop';

@Injectable()
export class BcfRouter extends Router {
  private _redirector: BcfRouterRedirectorNoop = inject(BcfRouterRedirectorNoop);

  constructor() {
    super();
  }

  public override async navigateByUrl(
    url: string | UrlTree,
    extras: NavigationBehaviorOptions = {
      skipLocationChange: false
    }
  ): Promise<boolean> {
    try {
      const parsedUrl: string = url instanceof UrlTree ? this.serializeUrl(url) : url;
      const rewritedUrl: string | undefined = this._redirector.redirectToUrl(parsedUrl);
      if (rewritedUrl) {
        return super.navigateByUrl(rewritedUrl, extras);
      }
      return true;
    } catch (err) {
      return super.navigateByUrl(url, extras);
    }
  }
}
