import {
  CmsRestrictionConfigs,
  createCmsRestrictionConfigsFromJsonHttp
} from '@bcf-v2-api-access/cms-api/models/cms-restriction-configs';

export class CmsPromotionsSlide {
  constructor(
    public readonly backgroundImg: string,
    public readonly titleHtml: string,
    public readonly subtitleHtml: string | undefined,
    public readonly badgeTitle: string[] | undefined,
    public readonly slugName: string,
    public readonly terms: string,
    public readonly backgroundColor: string | undefined,
    public readonly linkLabel: string | undefined,
    public readonly linkUrl: string | undefined,
    public readonly restrictionConfigs: CmsRestrictionConfigs | undefined
  ) {}

  public static fromJson(json: Record<string, any>): CmsPromotionsSlide {
    return new CmsPromotionsSlide(
      json['backgroundImg'],
      json['titleHtml'],
      json['subtitleHtml'],
      json['badgeTitle'],
      json['link'],
      json['terms'],
      json['backgroundColor'],
      json['button']?.label,
      json['button']?.link,
      json['restrictionConfigs'] ? createCmsRestrictionConfigsFromJsonHttp(json['restrictionConfigs']) : undefined
    );
  }
}

export class CmsPromotionsSlider {
  constructor(public readonly slides: CmsPromotionsSlide[]) {}

  public static fromJson(json: Record<string, any>): CmsPromotionsSlider {
    return new CmsPromotionsSlider(json['slides'].map(CmsPromotionsSlide.fromJson));
  }
}
