import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const NAVIGATOR: InjectionToken<Navigator> = new InjectionToken('NavigatorToken');

export function provideBrowserNavigator(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: NAVIGATOR, useFactory: () => navigator }]);
}

export function provideWorkerNavigator(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: NAVIGATOR, useFactory: () => navigator }]);
}

export function provideServerNavigator(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: NAVIGATOR, useFactory: () => new Object() }]);
}
