type CmsPrivacyPolicySection = {
  readonly title: string;
  readonly content: string;
};

export class CmsPrivacyPolicy {
  constructor(public readonly sections: CmsPrivacyPolicySection[]) {}

  public static fromJson(jsons: any[]): CmsPrivacyPolicy {
    return new CmsPrivacyPolicy(
      jsons.map((json: any) => ({
        title: json['title'],
        content: json['content']
      }))
    );
  }
}
