import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const INTLDATETIMEFORMAT: InjectionToken<Intl.DateTimeFormat> = new InjectionToken('IntlDateTimeFormatToken');

export function provideBrowserIntlDateTimeFormat(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: INTLDATETIMEFORMAT, useFactory: () => Intl.DateTimeFormat() }]);
}

export function provideServerIntlDateTimeFormat(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: INTLDATETIMEFORMAT, useFactory: () => new Object() }]);
}
