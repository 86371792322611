import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsApiConfig } from '@bcf-logic-api-access/misc/cms-api-config';
import { CmsExternalPage } from '@bcf-logic-shared/models/cms-external-page';
import { CmsRoute } from '@bcf-logic-shared/models/cms-route';
import { SharedSettings } from '@bcf-shared-settings/settings/shared-settings';
import * as R from 'rambdax';
import { Observable, defer, lastValueFrom, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CmsApi {
  public sportsDataPackage$: Observable<Record<string, any>> = defer(() => this._getSportsDataPackage()).pipe(
    shareReplay(1)
  );

  constructor(
    private _http: HttpClient,
    private _settings: SharedSettings,
    private _cmsApiConfig: CmsApiConfig
  ) {}

  private get _insertSlashOrNot(): string {
    return this._settings.environment.cmsSlashOnTheEnd ? '/' : '';
  }

  public getPromotedLeagues(): Promise<number[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(`${this._cmsApiConfig.jsonUrl()}promoted-leagues.json${this._insertSlashOrNot}`)
        .pipe(map(R.pipe(R.map<Record<string, number>, number>(R.prop('leagueId')), R.filter<number>(Boolean))))
    );
  }

  private _getSportsDataPackage(): Observable<Record<string, any>> {
    return this._http.get(`${this._cmsApiConfig.jsonUrl()}data-sports.json${this._insertSlashOrNot}`).pipe(
      catchError(() => {
        console.warn('FAILED LOAD OF data-sports.json');
        return of({});
      })
    );
  }

  public getRoutesConfig(): Promise<CmsRoute[]> {
    return lastValueFrom(
      this._http
        .get<Record<string, any>[]>(`${this._cmsApiConfig.jsonUrl()}cms-routes.json${this._insertSlashOrNot}`)
        .pipe(
          map(CmsRoute.fromJsonArray),
          catchError(() => {
            console.warn('FAILED LOAD OF cms-routes.json');
            return of([] as CmsRoute[]);
          })
        )
    );
  }

  public getCmsExternalPageContent(url: string): Promise<CmsExternalPage | undefined> {
    return lastValueFrom(
      this._http.get(url).pipe(
        map(CmsExternalPage.fromJson),
        catchError(() => {
          console.warn('FAILED LOAD OF cms-routes.json');
          return of(undefined);
        })
      )
    );
  }
}
