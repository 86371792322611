import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpError } from '@bcf-vanilla-ts-v1-api-access/common/http-client';

function extractHostname(errorUrl: string | undefined): string | undefined {
  if (!errorUrl) {
    return undefined;
  }
  const url: URL = new URL(errorUrl);
  return url.hostname;
}

function extractHeaders(headers: HttpHeaders): Record<string, string> {
  const container: Record<string, string> = {};
  for (const key of headers.keys()) {
    container[key] = headers.get(key)!;
  }
  return container;
}

export class HttpCustomError implements Error {
  constructor(
    public name: string,
    public message: string,
    public url: string | undefined,
    public statusCode: number,
    public errorContent?: unknown,
    public responseHeaders?: Record<string, string>
  ) {}

  public static fromHttpErrorResponse(error: HttpErrorResponse): HttpCustomError {
    const hostname: string | undefined = extractHostname(error.url ?? undefined);
    return new HttpCustomError(
      'HttpError',
      hostname ? `Http error: (https://${hostname}) with code: (${error.status})` : error.message,
      error.url ?? undefined,
      error.status,
      error.error,
      extractHeaders(error.headers)
    );
  }

  public static fromVanillaTsHttpError(error: HttpError): HttpCustomError {
    const hostname: string | undefined = extractHostname(error.url);
    return new HttpCustomError(
      'HttpError',
      hostname ? `Http error: (https://${hostname}) with code: (${error.status})` : error.statusText,
      error.url ?? undefined,
      error.status,
      error.error,
      error.responseHeaders
    );
  }
}
