import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { Observable, defer, fromEvent, merge, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { createIsUserActiveInAppChannel } from './utils';
@Injectable({
  providedIn: 'root'
})
export class UserInAppActivityBrowser {
  private _isUserActiveInAppChannel: WorkerSynchronizer<boolean> = createIsUserActiveInAppChannel(
    this._workerSynchronizerSpawner
  );

  private _isUserActiveInApp$: Observable<boolean> = this._isUserActiveInApp().pipe(shareReplay(1));

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private _platformId: string,
    private _workerSynchronizerSpawner: WorkerSynchronizerSpawner
  ) {}

  public init(): void {
    this._isUserActiveInApp$.subscribe((state: boolean) => this._isUserActiveInAppChannel.next(state));
  }

  public get isUserActiveInApp$(): Observable<boolean> {
    return this._isUserActiveInApp$.pipe(filter((state: boolean) => state === true));
  }

  public get isUserNotActiveInApp$(): Observable<boolean> {
    return this._isUserActiveInApp$.pipe(filter((state: boolean) => state === false));
  }

  private _isUserActiveInApp(): Observable<boolean> {
    if (isPlatformServer(this._platformId)) {
      return of(true);
    }
    // defer because InvalidEvent target in fromEvent function in SSR mode
    return defer(() =>
      merge(
        merge(fromEvent(this._document, 'click'), fromEvent(this._document, 'scroll')).pipe(
          startWith(undefined),
          map(() => true)
        ),
        merge(fromEvent(this._document, 'click'), fromEvent(this._document, 'scroll')).pipe(
          startWith(undefined),
          switchMap(() => timer(5 * 60 * 1000)),
          map(() => false)
        )
      )
    ).pipe(distinctUntilChanged());
  }
}
