import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export type EnvCms = {
  cmsApiUrl: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_CMS: InjectionToken<EnvCms> = new InjectionToken<EnvCms>('EnvCms');

export function provideEnvCms(envCms: EnvCms): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: ENV_CMS, useValue: envCms }]);
}
