import { DOCUMENT, isPlatformServer } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Inject, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { ENV_BASE, EnvBase } from '@bcf-v2-configs/env-base';
import { WorkerToolkitUi } from '@bcf-v2-platforms/platform-worker/browser';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { extractBaseLang } from './utils';

export function provideCurrentLangBrowserProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory:
        (
          document: Document,
          platformId: string,
          envBase: EnvBase,
          broadcastChannelSpawner: WorkerSynchronizerSpawner,
          workerToolkitUi: WorkerToolkitUi
        ) =>
        () => {
          if (isPlatformServer(platformId)) {
            return;
          }
          const langBase: string = extractBaseLang(envBase.availableLangs, document);
          workerToolkitUi.updateLangOnWorker(langBase);
          const langBaseChannel: WorkerSynchronizer<string> = broadcastChannelSpawner.spawn('langBaseChannel');
          langBaseChannel.next(langBase);
        },
      deps: [DOCUMENT, PLATFORM_ID, [new Inject(ENV_BASE)], WorkerSynchronizerSpawner, WorkerToolkitUi],
      multi: true
    }
  ]);
}
