function sanitizeOperatorToId(operatorName: string): string {
  return operatorName.toLowerCase().replaceAll(' ', '-');
}

export type CpCasinoGameKind = 'slots' | 'liveCasino' | 'tableGame' | 'jackpot' | 'mixed';

export function determineKind(isLiveCasino: boolean, isTableGame: boolean, isJackpot: boolean): CpCasinoGameKind {
  if (isJackpot) {
    return 'jackpot';
  }
  if (isTableGame) {
    return 'tableGame';
  }
  if (isLiveCasino) {
    return 'liveCasino';
  }
  return 'slots';
}

export type CpCasinoGameDataKind = {
  readonly isSlots: boolean;
  readonly isLiveCasino: boolean;
  readonly isTableGame: boolean;
  readonly isJackpot: boolean;
  readonly singleKind: CpCasinoGameKind;
  readonly gameKindRaw: CpCasinoGameKind | undefined;
};

export type CpCasinoGameDataProvider = {
  readonly id: string;
  readonly name: string;
};

export type CpCasinoGameData = {
  readonly gameId: number;
  readonly name: string;
  readonly slugName: string;
  readonly provider: CpCasinoGameDataProvider;
  readonly tags: string[];
  readonly iconName: string;
  readonly kind: CpCasinoGameDataKind;
  readonly isCoomingSoon: boolean;
  readonly hasJackpot: boolean;
  readonly demoModeForNotLoggedIn: boolean;
};

function createCpCasinoGameDataProvider(json: Record<string, any>): CpCasinoGameDataProvider {
  const providersMap: Record<string, string> = {
    'playn-go': `Play'n GO`
  };
  return {
    id: sanitizeOperatorToId(json['provider']),
    name: (providersMap[json['provider']] ?? json['provider']).replaceAll('-', ' ')
  };
}

function createCpCasinoGameDataKind(gameKind: string, isJackpot: boolean): CpCasinoGameDataKind {
  const isSlot: boolean = gameKind === 'Slots';
  const isLiveCasino: boolean = gameKind.startsWith('Live');
  const isTableGame: boolean = !gameKind.includes('Live') && gameKind !== 'Slots';
  return {
    isSlots: isSlot,
    isLiveCasino: isLiveCasino,
    isTableGame: isTableGame,
    isJackpot: isJackpot,
    singleKind: determineKind(isLiveCasino, isTableGame, isJackpot),
    gameKindRaw: gameKind as any
  };
}

export function createCpCasinoGamesDataFromHttpJsonArray(jsons: Record<string, any>[]): CpCasinoGameData[] {
  return jsons.map(createCpCasinoGameDataFromHttpJson);
}

export function createCpCasinoGameDataFromHttpJson(json: Record<string, any>): CpCasinoGameData {
  return {
    gameId: json['id'],
    name: json['name'],
    slugName: json['slug'],
    provider: createCpCasinoGameDataProvider(json),
    tags: json['tags'],
    iconName: json['slug'],
    kind: createCpCasinoGameDataKind(json['kind'], json['jackpot']),
    isCoomingSoon: json['coming_soon'],
    hasJackpot: json['jackpot'],
    demoModeForNotLoggedIn: json['demo'] ?? true
  };
}

export function createTestingCpCasinoGameDataPartial(partial: Partial<CpCasinoGameData>): CpCasinoGameData {
  return {
    ...partial
  } as CpCasinoGameData;
}

export function createTestingCpCasinoGameDataProviderPartial(
  partial: Partial<CpCasinoGameDataProvider>
): CpCasinoGameDataProvider {
  return {
    ...partial
  } as CpCasinoGameDataProvider;
}

export function createTestingCpCasinoGameDataKindPartial(partial: Partial<CpCasinoGameDataKind>): CpCasinoGameDataKind {
  return {
    ...partial
  } as CpCasinoGameDataKind;
}
