import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
  public override serialize(tree: UrlTree): string {
    return this._withTrailingSlash(super.serialize(tree));
  }

  private _withTrailingSlash(url: string): string {
    const splitOn: '?' | '#' = url.indexOf('?') > -1 ? '?' : '#';
    const pathArr: string[] = url.split(splitOn);
    if (!pathArr[0].endsWith('/')) {
      pathArr[0] += '/';
    }
    return pathArr.join(splitOn);
  }
}
