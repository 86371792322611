import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CRYPTO_SUBTLE: InjectionToken<SubtleCrypto> = new InjectionToken('CryptoSubtle');

export function provideBrowserCryptoSubtle(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: CRYPTO_SUBTLE, useFactory: () => crypto.subtle }]);
}

export function provideWorkerCryptoSubtle(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: CRYPTO_SUBTLE, useFactory: () => crypto.subtle }]);
}

export function provideServerCryptoSubtle(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: CRYPTO_SUBTLE, useFactory: () => globalThis.crypto.subtle }]);
}
