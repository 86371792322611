import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { asPromise } from '@bcf-vanilla-ts-v1-shared/misc/rx-helpers/as-promise';
import { transferRxNext } from '@bcf-vanilla-ts-v1-shared/misc/rx-helpers/transfer-rx-next';
import { Observable, ReplaySubject, fromEvent, mapTo, merge, of, shareReplay, startWith, tap } from 'rxjs';

export type BeforeInstallPromptEvent = {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<UserChoice>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
  preventDefault(): void;
};

type UserChoice = {
  outcome: 'accepted' | 'dismissed';
  platform: string;
};

@Injectable({ providedIn: 'root' })
export class PwaService {
  public beforeInstallPromptEvent$: ReplaySubject<BeforeInstallPromptEvent> =
    new ReplaySubject<BeforeInstallPromptEvent>(1);

  private _getIsAppInstalledShareRef$!: Observable<boolean>;

  constructor(
    @Inject(WINDOW) private _window: Window,
    @Inject(PLATFORM_ID) private _platformId: string
  ) {}

  public init(): void {
    if (isPlatformBrowser(this._platformId)) {
      fromEvent<BeforeInstallPromptEvent>(this._window, 'beforeinstallprompt')
        .pipe(tap((e: BeforeInstallPromptEvent) => e.preventDefault()))
        .subscribe(transferRxNext(this.beforeInstallPromptEvent$));
    }
  }

  public isAppInstalled(): Observable<boolean> {
    if (isPlatformBrowser(this._platformId)) {
      return (this._getIsAppInstalledShareRef$ ??= merge(
        this.beforeInstallPromptEvent$.pipe(mapTo(false)),
        fromEvent(this._window, 'appinstalled').pipe(mapTo(true))
      ).pipe(startWith(true), shareReplay(1)));
    }
    return of(true);
  }

  public async install(): Promise<void> {
    const beforeInstallEvent: BeforeInstallPromptEvent = await asPromise(this.beforeInstallPromptEvent$);
    beforeInstallEvent.prompt();
  }
}
