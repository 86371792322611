import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders } from '@angular/core';

export const SESSIONSTORAGE: InjectionToken<Storage> = new InjectionToken('SessionStorageToken');

class FakeSessionStorage implements Storage {
  [name: string]: any;
  public length!: number;
  public clear(): void {}
  public getItem(_key: string): string | null {
    return null;
  }
  public key(_index: number): string | null {
    return null;
  }
  public removeItem(_key: string): void {}
  public setItem(_key: string, _value: string): void {}
}

export function provideBrowserSessionStorage(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: SESSIONSTORAGE, useFactory: () => sessionStorage }]);
}

export function provideServerSessionStorage(): EnvironmentProviders {
  return makeEnvironmentProviders([{ provide: SESSIONSTORAGE, useFactory: () => new FakeSessionStorage() }]);
}
