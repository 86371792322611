import { EnvironmentProviders, Injectable, makeEnvironmentProviders } from '@angular/core';
import { IconsNotifier } from '@bcf-icons/svg/common';
import {
  WorkerSynchronizerSimple,
  WorkerSynchronizerSpawner
} from '@bcf-v2-platforms/platform-worker/worker-synchronizer';

@Injectable({ providedIn: 'root' })
class IconsNotifierBrowser extends IconsNotifier {
  constructor(private _workerSynchronizerSpawner: WorkerSynchronizerSpawner) {
    super();
    const iconsNotifierChannel: WorkerSynchronizerSimple =
      this._workerSynchronizerSpawner.spawnRaw('iconsNotifierChannel');
    iconsNotifierChannel.message$.subscribe(() => this.iconsLoaded$.next(undefined));
  }
}

export function provideIconsNotifierBrowser(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: IconsNotifier,
      useClass: IconsNotifierBrowser
    }
  ]);
}
