import { DOCUMENT, isPlatformServer } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import { WorkerSynchronizer, WorkerSynchronizerSpawner } from '@bcf-v2-platforms/platform-worker/worker-synchronizer';
import { extractCookies } from './utils';

/*
  Interval was used here because we track cookie changes also from external scripts
*/

export function provideCookiesBrowserProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory:
        (document: Document, platformId: string, workerSynchronizerSpawner: WorkerSynchronizerSpawner) => () => {
          if (isPlatformServer(platformId)) {
            return;
          }
          let currentCookiesStr: string = '';
          const cookiesSetChannel: WorkerSynchronizer<string> = workerSynchronizerSpawner.spawn('cookiesSetChannel');
          const cookiesChannel: WorkerSynchronizer<Record<string, string>> =
            workerSynchronizerSpawner.spawn('cookiesChannel');
          const cookiesWorkerInterceptorChannel: WorkerSynchronizer<void> =
            workerSynchronizerSpawner.spawn('cookiesWorkerInterceptor');

          const checkCookies = (): void => {
            if (currentCookiesStr !== document.cookie) {
              currentCookiesStr = document.cookie;
              const cookies: Record<string, string> = extractCookies(currentCookiesStr);
              cookiesChannel.next(cookies);
            }
          };

          cookiesSetChannel.message$.subscribe((cookie: string) => (document.cookie = cookie));

          cookiesWorkerInterceptorChannel.message$.subscribe(checkCookies);
          checkCookies();
          setInterval(checkCookies, 250);
        },
      deps: [DOCUMENT, PLATFORM_ID, WorkerSynchronizerSpawner],
      multi: true
    }
  ]);
}
